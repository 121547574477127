@keyframes slideDown {
    0% {
        opacity: .5;
        transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: .5;
        transform: translateY(-100%);
    }
}

.menu-header {
    background: $color-white;
    left: 0;
    position: fixed;
    top: 0;
    transition: $transition-btn; // dodano
    width: 100%;
    z-index: 100;

    .wrapper {
        align-items: center;
        display: flex;
        height: 140px;
        transition: $transition-btn; // dodano
    }

    .logo {
        width: 200px;

        img {
            display: block;
            height: auto;
            width: 100%;
        }
    }

    .menu-list {
        display: flex;
        margin: 10px 0 0 50px;
        width: 100%;

        .menu-item {
            .link {
                color: $color-text;
                display: inline-block;
                font-size: 1.8rem;
                font-weight: 600;
                padding: 20px;
                text-align: center;

                &:active,
                &:focus,
                &:hover {
                    color: $color-primary;
                }

                &.phone {
                    align-items: center;
                    color: $color-dark;
                    display: flex;
                    font-weight: 400;

                    &:active,
                    &:focus,
                    &:hover {
                        color: $color-primary;
                    }
                }
            }

            .btn {
                display: block;
                margin: 10px 0 0 16px;
                padding: 10px 25px;

                &.btn-green {
                    padding: 12px 25px;
                }
            }

            &.phone {
                margin-left: auto;

                .icon {
                    display: inline-block;
                    fill: $color-icon-dark;
                    height: 24px;
                    margin-right: 12px;
                    width: 24px;
                }
            }

            &.active {
                .link {
                    position: relative;

                    &:after {
                        background: currentColor;
                        bottom: 15px;
                        content: '';
                        height: 1px;
                        left: 20px;
                        position: absolute;
                        width: calc(100% - 40px);
                    }
                }
            }
        }
    }

    .mobile-menu {
        display: none;
        margin-left: auto;

        .open-menu,
        .close-menu {
            align-items: center;
            color: $color-text;
            cursor: pointer;
            display: none;
            font-size: 1.8rem;
            font-weight: 500;
            padding: 6px 0;
            text-transform: uppercase;
            transition: $transition-basic;

            svg {
                fill: $color-text;
                height: 30px;
                width: 30px;
            }

            &:active,
            &:focus,
            &:hover {
                svg {
                    fill: $color-primary;
                }
            }
        }

        .open-menu {
            &.active {
                display: none;
            }
        }

        .close-menu {
            display: none;

            &.active {
                display: flex;
            }
        }
    }

    &.fixed {
        animation: slideDown .7s;
        box-shadow: $box-shadow-bottom;
        left: 0;
        overflow: inherit;
        position: fixed;
        top: 0;
        transition: $transition-btn; // dodano
        width: 100%;
        z-index: 20;

        .wrapper {
            height: 60px;
            transition: $transition-btn; // dodano
        }

        .logo {
            width: 140px;
        }

        .menu-header {
            width: calc(100% - 160px);
        }

        .menu-list {
            margin-top: 0;
        }
    }

    &.is-hiding {
        animation: slideUp .7s;
    }
}

.transparent_bg .menu-header,
.home .menu-header {
    background: transparent;

    .mobile-menu .close-menu svg, 
    .mobile-menu .open-menu svg {
        fill: $color-white
    }

    .menu-list .menu-item {
        .link {
            color: $color-white;

            &:active,
            &:focus,
            &:hover {
                color: $color-primary;
            }
        }

        &.phone .icon {
            fill: $color-icon-superlight
        }

        .btn-outline-blue {
            color: $color-white;
        }
    }

    &.fixed {
        background: $color-background;
    }
}

@media screen and (max-width: 1300px) {
    .menu-header {
        .logo {
            width: 180px;
        }

        .menu-list {
            .menu-item {
                .link {
                    padding: 20px 14px;
                }

                .btn {
                    padding: 10px 12px;
                }

                &.active {
                    .link {    
                        &:after {
                            width: calc(100% - 30px);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1140px) {
    .menu-header {
        .wrapper {
            padding: 0 20px;
        }
        
        .logo {
            width: 180px;
        }

        .menu-list {
            margin: 11px 0 0 30px;
            .menu-item {
                .link {
                    font-size: 1.6rem;
                    padding: 20px 10px;
                }

                &.active {
                    .link {    
                        &:after {
                            width: calc(100% - 20px);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .menu-header {
        .wrapper {
            height: 60px;
        }

        .menu-list {
            display: none;
            margin: 0;

            &.show {
                box-shadow: $box-shadow-bottom;
                display: flex;
                position: absolute;
                width: 100%;
                top: 60px;
                left: 0;
                flex-direction: column;
                padding: 0;
                z-index: 100;

                .menu-item {
                    background: $color-white;
                    border-bottom: 1px solid $color-border-light;

                    &:last-child {
                        border: none;
                    }

                    .link {
                        color: $color-text;
                        display: inline-block;
                        padding: 15px 30px;
                        text-align: center;
                        transition: $transition-basic;
                        width: 100%;

                        &.phone {
                            display: flex;
                            justify-content: center;
                        }
                    }

                    &.phone {
                        margin-left: 0;
                    }

                    .btn {
                        background: $color-secondary-text;
                        border-radius: 0px;
                        display: block;
                        margin: 0;
                        text-align: center;
                        width: 100%;

                        &:active,
                        &:focus,
                        &:hover {
                            background: $color-primary;
                            box-shadow: none;
                        }

                        &.btn-outline {
                            background: $color-secondary-text;
                            border: none;
                            color: $color-white;

                            &:active,
                            &:focus,
                            &:hover {
                                background: $color-white;
                                color: $color-secondary-text;
                            }
                        }

                        &.btn-outline-blue {
                            background: none;
                            border: none;
                            color: $color-text;

                            &:active,
                            &:focus,
                            &:hover {
                                background: $color-white;
                                color: $color-secondary-text;
                            }
                        }
                    }

                    &:active,
                    &:focus,
                    &:hover {
                        background: $color-white;

                        .link {
                            color: $color-primary;
                        }
                    }

                    &.active {
                        .link {
                            color: $color-primary;
                             
                            &:after {
                                display: none
                            }
                        }
                    }
                }
            }
        }

        .mobile-menu {
            display: block;

            .open-menu {
                display: flex;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .menu-header {
        .logo {
            width: 160px;
        }
    }
}

@media screen and (max-width: 560px) {
    .menu-header {
        .logo {
            width: 140px;
        }
    }
}
