.section-news {
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 80px;

  .wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .btn.btn-with-arrow {
    left: inherit;
    margin-left: auto;
    transform: none;
  }

  .news-list-wrapper {
    margin: 50px -20px 0 -20px;
    position: relative;
    width: calc(100% + 40px);
  }

  .news-list {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    transition: $transition-slider;
  }

  .news-item {
    list-style: none;

    .photo-wrapper {
      border-radius: 5px;
      display: block;
      height: auto;
      padding-bottom: 50%;
      position: relative;
      overflow: hidden;
      width: 100%;
  
      img,
      .thumbnail {
        display: block;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        transition: $transition-slow;
        width: 100%;
      }
  
      &:active,
      &:focus,
      &:hover {
        img,
        .thumbnail {
          transform: scale(1.1);
        }
      }
    }

    .news-title {
      color: $color-dark;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-weight: 600;
      font-size: 21px;
      line-height: 1.25;
      margin: 15px 0 0 0;
      overflow: hidden;
      transition: $transition-basic;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      width: 100%;
  
      &:active,
      &:focus,
      &:hover {
        color: $color-primary
      }
    }
  
    .data-time {
      color: darken($color-gray, 4%);
      display: inline-block;
      font-size: 16px;
      margin-top: 23px;
    }
  }

  .slider-dots {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 25px 0 40px 0;

    span {
      background: $color-gray-footer;
      border: 5px solid $color-white;
      border-radius: 14px;
      cursor: pointer;
      display: block;
      height: 16px;
      margin: 0 2px;
      transition: $transition-basic;
      width: 16px;

      &.active {
        background: $color-gray;
        width: 56px;
      }

      &:hover {
        background: $color-gray;
      }
    }
  }

  @for $i from 1 through 32 {
    .news-list[data-count="#{$i}"] {
      margin: 0;
      width: calc(100% * #{$i});

      .news-item {
        margin: 0 20px;
        width: calc(((100% / 3) / #{$i}) - 41px);
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .section-news {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 840px) {
  .section-news {
    padding-top: 40px;
    padding-bottom: 40px;

    .news-item .news-title {
      font-size: 18px;
    }

    .news-list-wrapper {
      margin: 30px -10px 0 -10px;
      width: calc(100% + 20px);
    }

    @for $i from 1 through 32 {
      .news-list[data-count="#{$i}"] {
        .news-item {
          margin: 0 10px;
          width: calc((50% / #{$i}) - 21px);
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .section-news {
    @for $i from 1 through 32 {
      .news-list[data-count="#{$i}"] {
        .news-item {
          width: calc((50% / #{$i}) - 21px);
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .section-news {
    padding-top: 20px;
    padding-bottom: 20px;

    .news-list-wrapper {
      margin: 20px -10px 0 -10px;
    }

    @for $i from 1 through 32 {
      .news-list[data-count="#{$i}"] {
        .news-item {
          width: calc((100% / #{$i}) - 21px);
        }
      }
    }
  }
}