*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 62.5%;
}

body {
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: 400;
}

.wrapper {
    margin: 0 auto;
    max-width: 1360px;
    padding: 0 40px;
    width: 100%;

    &.narrow {
        max-width: 980px;
    }
}

ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

figure, figcaption {
    margin: 0;
    padding: 0;
}

.page-subtitle {
    color: $color-text-dark;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 35px 0;
    text-align: center;

    .link {
        color: $color-text;

        &:active,
        &:focus,
        &:hover {
            color: $color-primary;
        }
    }
}

.section-title {
    color: $color-text;
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 35px 0 25px 0;
    text-align: center;

    .link {
        color: $color-text;

        &:active,
        &:focus,
        &:hover {
            color: $color-primary;
        }
    }
}

.section-subtitle {
    color: $color-text;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.2;

    &.light {
        color: $color-white;
    }
}

.upper-text {
    color: $color-primary;
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.intro {
    color: $color-text;
    display: block;
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 1.3;
    margin: 30px 0 40px 0;

    &.light {
        color: $color-white;
    }
}

.header-title {
    padding: 0 40px;

    .page-subtitle {
        margin: 30px 0 0 0;
    }

    .intro {
        margin: 20px 0 30px 0;
        text-align: center;
    }

    .btn {
        display: inline-block;
        margin-top: 25px;
    }
}

.uppercase {
    text-transform: uppercase;
}

.icon {
    color: $color-icon;
    fill: $color-icon;
}

.cookies {
    background: $color-white;
    bottom: 0;
    font-size: 16px;
    height: 50px;
    position: fixed;
    width: 100%;
    z-index: 10000001; 

    .wrapper {
        align-items: center;
        color: $color-text;
        display: flex;
        height: 100%;
        justify-content: center;

        .close {
            cursor: pointer;
            left: 40px;
            position: absolute;

            .icon {
                fill: $color-text;
                height: 22px;
                transition: $transition-basic;
                width: 22px;
            }

            &:active,
            &:focus,
            &:hover {
                .icon {
                    fill: $color-primary;
                }
            }
        }
    }
}

div.main {
    padding-top: 40px!important;
}

/* media screen 1600 1320 1040 840 720 560 320 */
@media screen and (max-width: 1360px) {
    .cookies .wrapper {    
        .close {
            left: 13px;
        }
    }
}

@media screen and (max-width: 1040px) {
    .wrapper {
        padding: 0 30px;
    }

    .page-subtitle {
        font-size: 4.5rem;
    }

    .section-title {
        font-size: 4rem;
    }

    .header-title {
        padding: 0 30px;

        .intro {
            font-size: 1.8rem;
            margin: 15px 0 20px 0;
        }

        .btn {
            margin-top: 20px;
        }
    }

    .cookies .wrapper {
        padding: 0 20px 0 45px;
    }
}

@media screen and (max-width: 720px) {
    .wrapper {
        padding: 0 30px;
    }

    .page-subtitle {
        font-size: 4rem;
    }

    .section-title {
        font-size: 3.5rem;
    }

    .cookies {
        font-size: 14px;
    }
}

@media screen and (max-width: 560px) {
    .wrapper {
        padding: 0 20px;
    }

    .page-subtitle {
        font-size: 3.5rem;
    }

    .section-title {
        font-size: 3rem;
    }

    .header-title {
        padding: 0 20px;

        .intro {
            font-size: 1.6rem;
            margin: 10px 0;
        }

        .btn {
            margin-top: 15px;
        }
    }

    .cookies .wrapper {
        font-size: 12px;
        padding: 0 20px 0 40px;

        .close {
            left: 10px;
        }
    }
}


@media screen and (max-width: 400px) {
    .page-subtitle {
        font-size: 3rem;
    }

    .section-title {
        font-size: 2.5rem;
    }

    .header-title {
        .btn {
            width: 100%;
        }
    }

    .cookies {
        height: auto;
        padding: 5px 0px;
    }
}
