.ss-cn-info {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);

    .cookie-banner-wrapper{
        background: $color-white;
        border-radius: 0;
        bottom: 30px;
        box-shadow: 0px 5px 20px rgba(49, 83, 78, 0.5);
        left: 50%;
        max-width: 960px;
        padding: 30px;
        position: fixed;
        transform: translateX(-50%);
        width: 90%;
        z-index: 100000000000;
    }

    &.is-hidden {
        opacity: 0;
        pointer-events: none;
        top: 100%;
    }

    &-text {
        p {
            margin-top: 0;
        }
        a {
            color: $color-primary;
        }
    }

    &-buttons {
        margin-top: 30px;
        text-align: center;

        .btn {
            margin: 10px;
            display: inline-block;

            &.ss-cn-info-button-show-extended {
                background: none;
                border: none;
                color: $color-primary;

                &:hover {
                    color: $color-primary;
                }
            }

            &.dark-gray {
                background: $color-white;
                border: 1px solid $color-primary;
                color: $color-primary;

                &:hover {
                    background: $color-white;
                    border: 1px solid $color-primary;
                    color: $color-primary;
                }
            }
        }
    }
}

.ss-cn-info-extended {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);

    .cookie-banner-wrapper{
        background: $color-white;
        border-radius: 15px;
        box-shadow: 0px 5px 20px rgba(49, 83, 78, 0.5);
        left: 50%;
        max-height: 90vh;
        max-width: 960px;
        overflow: hidden;
        padding: 0 0 80px 0;
        position: fixed;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 90%;
        z-index: 100000000000;
    }

    &.is-hidden {
        opacity: 0;
        pointer-events: none;
        top: 100%;
    }

    &-wrapper {
        height: calc(90vh - 80px);
        overflow: auto;
        position: relative;
        padding: 30px;
    }

    &-text {
        &>p:first-child {
            margin-top: 0;
        }
        &>p:last-child{
            margin-top: 20px;
        }
        a{
            color: $color-primary;
            &.btn{
                color: $color-white;
                display: inline-block;
            }
        }
    }

    &-buttons {
        align-items: center;
        background: $color-white;
        box-shadow: 0 -8px 8px rgba(0, 0, 0, .075);
        bottom: 0;
        display: flex;
        height: 80px;
        left: 0;
        padding: 0 30px;
        position: absolute;
        right: 0;
        width: 100%;
        z-index: 100000000001;

        .btn+.btn {
            margin-left: auto;
        }
    }

    &-checkboxes {
        list-style-type: none;
        margin: 30px -30px;
        padding: 0;
    }

    .ss-cn-consent {
        padding: 25px 30px 30px 30px;
        position: relative;

        input[type="checkbox"] {
            height: 25px;
            left: 0;
            opacity: 0;
            top: 25px;
            width: 25px;

            &:before,
            &:after {
                display: none;
            }

            &:checked+.ss-cn-consent-label-text:before {
                opacity: 1;
            }

            &:disabled+.ss-cn-consent-label-text:after,
            &:disabled+.ss-cn-consent-label-text:before {
                opacity: .5;
                cursor: not-allowed;
            }
        }

        &-label {
            display: flex;
            position: relative;
        }

        &:nth-child(odd) {
            background: #f5f5f5;
        }

        &-label-text {
            color: #000;
            display: inline-block;
            margin-left: 15px;
            width: calc(100% - 35px);

            &:before {
                border: 2px solid #000;
                border-width: 0 2px 2px 0;
                content: "";
                height: 10px;
                left: 7px;
                opacity: 0;
                padding: 0;
                position: absolute;
                top: 15px;
                transform: translateY(-11px) rotate(45deg);
                width: 7px;
                z-index: 1;
            }

            &:after {
                background: $color-white;
                border: 2px solid #000;
                border-radius: 2px;
                content: "";
                height: 20px;
                left: 0;
                position: absolute;
                top: 1px;
                width: 20px;
            }
        }

        &-description {
            font-size: 12px;
            margin: 10px 0 0 0;
        }
    }
}

.yt-player-cn-info-notice,
.slideshare-player-cn-info-notice,
.maps-cn-info-notice {
    display: none;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    border: 2px solid $color-primary;
    position: relative;
    z-index: 10;
    margin-top: 20px;

    .btn {
        flex-shrink: 0;
        margin: 0 0 0 20px;
        color: #fff!important;
    }

    &.is-active {
        display: flex;
    }
}

.smartsupp-cookie-button{
    position: fixed;
    background: $color-primary;
    color: $color-white;
    display: flex;
    right: 20px;
    bottom: 20px;
    height: 50px;
    padding: 0 0 0 20px;
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999;

    span{
        display: inline-block;
        width: 70px;
        overflow: hidden;
        transition: 1s all;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 20px;
        font-weight: bold;
    }
    div{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background: $color-icon-dark;
    }

    &:hover{
        span{
            width: 270px;
        }
    }
}

@media (max-width: 1024px) {
    .ss-cn-info {
        padding: 20px;

        .ss-cn-info-text {
            font-size: 12px;

            p {
                margin: 10px 0;
            }
        }

        .ss-cn-info-buttons {
            margin-top: 15px;

            .btn {
                margin: 5px;
            }
        }
    }
}

@media (max-width: 720px) {

    .ss-cn-info,
    .ss-cn-info-extended {
        border-radius: 0;
        bottom: 0;
        width: 100%;
    }

    .ss-cn-info-extended {
        height: 100%;
        max-height: 100%;

        .ss-cn-info-extended-text p {
            font-size: 13px;
        }
    }
}

@media (max-width: 640px) {
    .ss-cn-info {
        padding: 12px;

        &-text p {
            line-height: 1.3;
        }
    }

    .ss-cn-info-extended-wrapper {
        padding: 20px;
    }

    .ss-cn-info-extended {
        padding-bottom: 60px;
    }

    .ss-cn-info-extended-checkboxes {
        margin: 20px -20px;
    }

    .ss-cn-info-extended .ss-cn-consent {
        padding: 15px 20px 20px;
    }

    .ss-cn-info-extended-buttons {
        height: 60px;
        padding: 0 5px;

        .btn {
            margin: 10px 0;
        }
    }
}
