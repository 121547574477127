.testimonials {
  overflow: hidden;
  margin: 60px 0 0 0;

  .intro {
    text-align: center;
  }

  .testimonials-list-wrapper {
    margin: 80px 0 0 0;
  }

  .testimonials-list {
    align-items: stretch;
    display: flex;
    flex-wrap: nowrap;
    transition: $transition-slider;
  }

  .testimonials-item {
    background: $color-background-blue-light;
    border-radius: 6px;
    margin: 20px;
    padding: 60px 38px 65px 38px;
    position: relative;

    &:before {
      background-image: url('../images/quote-outline.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      content: "";
      height: 46px;
      left: 40px;
      position: absolute;
      top: -20px;
      width: 62px;
      z-index: 0;
    }

    &-text {
      color: $color-text;
      line-height: 1.75;

      .btn {
        display: block;
        margin: 35px auto 0 auto;
        max-width: 220px;
        text-align: center;
        width: 100%;
      }

      .text-bold {
        display: block;
        font-size: 21px;
        font-weight: 600;
        line-height: 1.24;
        margin: 20px auto;
        max-width: 190px;
        text-align: center;
        width: 100%;
      }
    }

    .data-time {
      bottom: 43px;
      left: 38px;
      position: absolute;

      a {
        color: $color-primary;
        font-weight: 600;
      }
    }

    .icon-link {
      align-items: center;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      height: 48px;
      margin: 0 auto;
      width: 48px;

      .icon {
        fill: $color-white;
        height: 20px;
        width: 20px;
      }

      &.facebook {
        background: $color-social-fb;

        .icon {
          left: -1px;
          position: relative;
          top: 1px;
        }

        &:active,
        &:focus,
        &:hover {
          background: darken($color-social-fb, 10%);
        }
      }

      &.twitter {
        background: $color-social-twitter;

        &:active,
        &:focus,
        &:hover {
          background: darken($color-social-twitter, 10%);
        }
      }

      &.linkedin {
        background: $color-social-linkedin;

        &:active,
        &:focus,
        &:hover {
          background: darken($color-social-linkedin, 10%);
        }
      }

      &.youtube {
        background: $color-social-youtube;
        color: $color-white;

        &:active,
        &:focus,
        &:hover {
          background: darken($color-social-youtube, 10%);
        }
      }
    }
  }

  .slider-dots {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 25px 0 60px 0;

    span {
      background: $color-gray-footer;
      border: 5px solid $color-white;
      border-radius: 14px;
      cursor: pointer;
      display: block;
      height: 16px;
      margin: 0 2px;
      transition: $transition-basic;
      width: 16px;

      &.active {
        background: $color-gray;
        width: 56px;
      }

      &:hover {
        background: $color-gray;
      }
    }
  }

  @for $i from 1 through 32 {
    .testimonials-list[data-count="#{$i}"] {
      margin: 0;
      width: calc(100% * #{$i});

      .testimonials-item {
        width: calc(((100% / 3) / #{$i}) - 40px);
      }
    }
  }
}

.benefits .testimonials {
    margin-top: 0;
}

@media screen and (max-width: 1200px) {
  .testimonials {
    .testimonials-item {
      margin: 10px;
      padding: 40px 30px 50px 30px;

      &:before {
        left: 30px;
      }

      .data-time {
        bottom: 20px;
        left: 30px;
      }
    }

    @for $i from 1 through 32 {
      .testimonials-list[data-count="#{$i}"] {
        .testimonials-item {
          width: calc(((100% / 3) / #{$i}) - 20px);
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .testimonials {
    .testimonials-item {
      font-size: 15px;
      margin: 7px;
      padding: 40px 25px 50px 25px;

      &:before {
        left: 25px;
      }

      .data-time {
        bottom: 20px;
        left: 25px;
      }
    }

    @for $i from 1 through 32 {
      .testimonials-list[data-count="#{$i}"] {
        .testimonials-item {
          width: calc((50% / #{$i}) - 14px);
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .testimonials {
    @for $i from 1 through 32 {
      .testimonials-list[data-count="#{$i}"] {
        margin: 0;
        width: calc(100% * #{$i});
  
        .testimonials-item {
          width: calc((100% / #{$i}) - 14px);
        }
      }
    }
  }
}
