.trial-cta {
    overflow: hidden;
    padding: 70px 0;
    position: relative;

    .trial-cta-parallax-bg {
        background-image: url('../images/bg-trial.jpg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        content: "";
        height: 140%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        will-change: transform;
        z-index: 0;
    }

    .wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
    }

    img {
        display: block;
        height: auto;
        max-width: 190px;
    }

    .page-subtitle {
        color: $color-white;
        margin: 30px 0 20px 0;
    }

    .intro {
        color: $color-white;
        margin: 0 auto 30px auto;
        max-width: 610px;
        text-align: center;
    }

    .btn {
        margin-top: 10px;
    }
}

@media screen and (max-width: 1040px) {
    .trial-cta {
        img {
            width: 150px;
        }

        .intro {
            font-size: 1.8rem;
        }
    }
}

@media screen and (max-width: 720px) {
    .trial-cta {
        padding: 50px 0;

        .page-subtitle {
            font-size: 3.7rem;
        }

        img {
            width: 130px;
        }
    }
}

@media screen and (max-width: 560px) {
    .trial-cta {
        padding: 40px 0;

        img {
            width: 110px;
        }

        .intro {
            font-size: 1.6rem;
        }
    }
}
