.tabs {
    background: $gradient-background;
    position: relative;

    &:before {
        background-image: url('../images/bg-blue-mask.png');
        background-repeat: repeat-y;
        background-position: center center;
        background-size: contain;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.tab {
    margin-top: 70px;
    text-align: center;

    .tablinks {
        background: none;
        border: none;
        cursor: pointer;
        color: $color-white;
        font-size: 2.1rem;
        font-weight: 700;
        margin: 0 18px;
        outline: none;
        padding: 25px 35px;
        position: relative;
        transition: $transition-basic;

        &:after {
            background: rgba($color-dark, .3);
            border-radius: 3px;
            bottom: -2px;
            content: '';
            display: block;
            height: 5px;
            left: 0;
            position: absolute;
            transition: $transition-basic;
            width: 100%;
        }

        &:before {
            background: $color-white;
            border-radius: 3px;
            bottom: -2px;
            content: '';
            display: block;
            height: 5px;
            left: 50%;
            position: absolute;
            transition: $transition-basic;
            transform: translateX(-50%);
            width: 0%;
            z-index: 1; 
        }

        &:hover,
        &.active {
            &:before {
                width: 100%;
            }
        }
    }
}

.tab-block {
    .tabcontent {
        display: none;
        padding: 0 20px;

        &.active {
            display: block;
        }

        .intro {
            color: $color-white;
            font-size: 2.1rem;
        }
    }
}

.slider-photo-wrapper {
    align-items: flex-end;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    padding-bottom: 35%;
    position: relative;

    .photo-wrapper {
        bottom: 0;
        left: 50%;
        max-width: 980px;
        position: absolute;
        transition: $transition-basic;
        transform: translateX(-50%);
        width: 50%;

        img {
            display: block;
            height: auto;
            max-height: 580px;
            opacity: 1;
            max-width: inherit;
            width: 100%;
        }
    }

    &[data-current="1"] {
        .photo-wrapper {
            &:nth-child(1) {
                box-shadow: $box-shadow;
                left: 50%;
                max-width: 980px;
                z-index: 3;
            }

            &:nth-child(2) {
                left: 73%;
                max-width: 650px;
                opacity: .5;
                z-index: 2;
            }

            &:nth-child(3) {
                left: 60%;
                max-width: 350px;
                opacity: 0;
                z-index: 1;
            }

            &:nth-child(4) {
                left: 40%;
                max-width: 350px;
                opacity: 0;
                z-index: 1;
            }

            &:nth-child(5) {
                left: 27%;
                max-width: 650px;
                opacity: .5;
                z-index: 2;
            }
        }
    }

    &[data-current="2"] {
        .photo-wrapper {
            &:nth-child(1) {
                left: 27%;
                max-width: 650px;
                opacity: .5;
                z-index: 2;
            }

            &:nth-child(2) {
                box-shadow: $box-shadow;
                left: 50%;
                max-width: 980px;
                z-index: 3;
            }

            &:nth-child(3) {
                left: 73%;
                max-width: 650px;
                opacity: .5;
                z-index: 2;
            }

            &:nth-child(4) {
                left: 60%;
                max-width: 350px;
                opacity: 0;
                z-index: 1;
            }

            &:nth-child(5) {
                left: 40%;
                max-width: 350px;
                opacity: 0;
                z-index: 1;
            }
        }
    }

    &[data-current="3"] {
        .photo-wrapper {
            &:nth-child(1) {
                left: 40%;
                max-width: 350px;
                opacity: 0;
                z-index: 1;
            }

            &:nth-child(2) {
                left: 27%;
                max-width: 650px;
                opacity: .5;
                z-index: 2;
            }

            &:nth-child(3) {
                box-shadow: $box-shadow;
                left: 50%;
                max-width: 980px;
                z-index: 3;
            }

            &:nth-child(4) {
                left: 73%;
                max-width: 650px;
                opacity: .5;
                z-index: 2;
            }

            &:nth-child(5) {
                left: 60%;
                max-width: 350px;
                opacity: 0;
                z-index: 1;
            }
        }
    }

    &[data-current="4"] {
        .photo-wrapper {
            &:nth-child(1) {
                left: 60%;
                max-width: 350px;
                opacity: 0;
                z-index: 1;
            }

            &:nth-child(2) {
                left: 40%;
                max-width: 350px;
                opacity: 0;
                z-index: 1;
            }

            &:nth-child(3) {
                left: 27%;
                max-width: 650px;
                opacity: .5;
                z-index: 2;
            }

            &:nth-child(4) {
                box-shadow: $box-shadow;
                left: 50%;
                max-width: 980px;
                z-index: 3;
            }

            &:nth-child(5) {
                left: 73%;
                max-width: 650px;
                opacity: .5;
                z-index: 2;
            }
        }
    }

    &[data-current="5"] {
        .photo-wrapper {
            &:nth-child(1) {
                left: 73%;
                max-width: 650px;
                opacity: .5;
                z-index: 2;
            }

            &:nth-child(2) {
                left: 60%;
                max-width: 350px;
                opacity: 0;
                z-index: 1;
            }

            &:nth-child(3) {
                left: 40%;
                max-width: 350px;
                opacity: 0;
                z-index: 1;
            }

            &:nth-child(4) {
                left: 27%;
                max-width: 650px;
                opacity: .5;
                z-index: 2;
            }

            &:nth-child(5) {
                box-shadow: $box-shadow;
                left: 50%;
                max-width: 980px;
                z-index: 3;
            }
        }
    }

    .navigation-wrapper {
        bottom: 0;
        display: flex;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 4;

        .prev,
        .next {
            display: block;
            height: 100%;
            width: 25%;
        }

        .next {
            margin-left: auto;
        }
    }
}

@media screen and (max-width: 1640px) {
    .tab .tablinks {
        padding: 25px 15px;
    }
}

@media screen and (max-width: 1440px) {
    .tab .tablinks {
        font-size: 1.8rem;
        margin: 0 10px;
    }

    .slider-photo-wrapper {    
        &[data-current="1"] {
            .photo-wrapper {    
                &:nth-child(2) {
                    max-width: 450px;
                }
    
                &:nth-child(5) {
                    max-width: 450px;
                }
            }
        }
    
        &[data-current="2"] {
            .photo-wrapper {
                &:nth-child(1) {
                    max-width: 450px;
                }
    
                &:nth-child(3) {
                    max-width: 450px;
                }
            }
        }
    
        &[data-current="3"] {
            .photo-wrapper {    
                &:nth-child(2) {
                    max-width: 450px;
                }
    
                &:nth-child(4) {
                    max-width: 450px;
                }
            }
        }
    
        &[data-current="4"] {
            .photo-wrapper {    
                &:nth-child(3) {
                    max-width: 450px;
                }
    
                &:nth-child(5) {
                    max-width: 450px;
                }
            }
        }
    
        &[data-current="5"] {
            .photo-wrapper {
                &:nth-child(1) {
                    max-width: 450px;
                }
    
                &:nth-child(4) {
                    max-width: 450px;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .tab .tablinks {
        font-size: 1.6rem;
        margin: 0 10px;
        padding: 20px 5px;
    }

    .slider-photo-wrapper {    
        &[data-current="1"] {
            .photo-wrapper {    
                &:nth-child(2) {
                    max-width: 350px;
                }
    
                &:nth-child(5) {
                    max-width: 350px;
                }
            }
        }
    
        &[data-current="2"] {
            .photo-wrapper {
                &:nth-child(1) {
                    max-width: 350px;
                }
    
                &:nth-child(3) {
                    max-width: 350px;
                }
            }
        }
    
        &[data-current="3"] {
            .photo-wrapper {    
                &:nth-child(2) {
                    max-width: 350px;
                }
    
                &:nth-child(4) {
                    max-width: 350px;
                }
            }
        }
    
        &[data-current="4"] {
            .photo-wrapper {    
                &:nth-child(3) {
                    max-width: 350px;
                }
    
                &:nth-child(5) {
                    max-width: 350px;
                }
            }
        }
    
        &[data-current="5"] {
            .photo-wrapper {
                &:nth-child(1) {
                    max-width: 350px;
                }
    
                &:nth-child(4) {
                    max-width: 350px;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .tab .tablinks {
        font-size: 1.8rem;
        margin: 0 auto;
        max-width: 540px;
        width: calc(100% - 40px);
    }

    .slider-photo-wrapper {
        padding-bottom: 60%;

        .photo-wrapper {
            width: 85%;
        }

        &[data-current="1"] {
            .photo-wrapper {    
                &:nth-child(2) {
                    opacity: 0;
                }
    
                &:nth-child(5) {
                    opacity: 0;
                }
            }
        }
    
        &[data-current="2"] {
            .photo-wrapper {
                &:nth-child(1) {
                    opacity: 0;
                }
    
                &:nth-child(3) {
                    opacity: 0;
                }
            }
        }
    
        &[data-current="3"] {
            .photo-wrapper {    
                &:nth-child(2) {
                    opacity: 0;
                }
    
                &:nth-child(4) {
                    opacity: 0;
                }
            }
        }
    
        &[data-current="4"] {
            .photo-wrapper {    
                &:nth-child(3) {
                    opacity: 0;
                }
    
                &:nth-child(5) {
                    opacity: 0;
                }
            }
        }
    
        &[data-current="5"] {
            .photo-wrapper {
                &:nth-child(1) {
                    opacity: 0;
                }
    
                &:nth-child(4) {
                    opacity: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .tab-block {
        .tabcontent {
            .intro {
                font-size: 1.8rem;
            }

            .desc {
                font-size: 1.6rem;
            }
        }
    }
}
