.compatibility {
    overflow: hidden;
    padding: 0 0 50px 0;

    .section-title,
    .intro {
        padding: 0 30px;
    }

    .intro {
        text-align: center;
    }

    .slider-trade-block {
        .container {
            margin: -20px 0;
            overflow: hidden;
            padding: 20px 0;
        }
    }

    .slider-trade-list {
        align-items: center;
        display: flex;
        margin: auto;
        padding: 40px 0;
        transition: all .5s ease-out;
        width: 2550px;
        will-change: transform;

        &[data-current="1"] {
            transform: translateX(calc(50vw - 265px));
        }

        &[data-current="2"] {
            transform: translateX(calc(50vw - 755px));
        }

        &[data-current="3"] {
            transform: translateX(calc(50vw - 1265px));
        }

        &[data-current="4"] {
            transform: translateX(calc(50vw - 1775px));
        }

        &[data-current="5"] {
            transform: translateX(calc(50vw - 2285px));
        }

        &[data-current="6"] {
            transform: translateX(calc(50vw - 2795px));
        }

        &[data-current="7"] {
            transform: translateX(calc(50vw - 3305px));
        }

        &[data-current="8"] {
            transform: translateX(calc(50vw - 3815px));
        }

        &[data-current="9"] {
            transform: translateX(calc(50vw - 4325px));
        }

        &[data-current="10"] {
            transform: translateX(calc(50vw - 4835px));
        }

        .slider-trade-item {
            margin: 20px;
            will-change: transform;

            .figure {
                align-items: center;
                display: flex;
                flex-direction: column;
                height: 540px;
                overflow: hidden;
                position: relative;
                transition: $transition-basic;
                width: 470px;

                .photo-overlay {
                    background: $color-background-overlay;
                    border-radius: 6px;
                    display: block;
                    height: 100%;
                    overflow: hidden;
                    position: relative;
                    width: 100%;

                    img {
                        display: block;
                        height: 100%;
                        opacity: .53;
                        object-fit: cover;
                        transition: all .5s ease-out;
                        width: 100%;
                    }
                }

                .figcaption {
                    bottom: 0;
                    left: 0;
                    padding: 40px 50px;
                    pointer-events: none;
                    position: absolute;
                    transition: $transition-basic;
                    width: 100%;
                }

                .title {
                    color: $color-white;
                    font-size: 4.5rem;
                    font-weight: 700;
                    margin: 0;
                }

                .description {
                    color: $color-white;
                    font-size: 2.1rem;
                    margin: 20px 0 36px 0;
                }

                .btn.btn-with-arrow {
                    pointer-events: auto;
                    width: 200px;
                } 
            }

            &.active {
                .figure {
                    box-shadow: $box-shadow-overlay;
                    transform: scale(1.075);

                    .photo-overlay img {
                        opacity: .5;
                        transform: scale(1.25);
                        transition-duration: 5s;
                    }
                }
            }
        }
    }

    .text-extra {
        color: $color-text-dark;
        font-size: 3.8rem;
        text-align: center;

        .btn {
            display: block;
            font-size: 1.6rem;
            margin: 30px auto;
            padding: 16px 25px;
            width: 200px;
        }
    }

    .slider-dots {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 25px 0 60px 0;

        span {
            background: $color-gray-footer;
            border: 5px solid $color-white;
            border-radius: 14px;
            cursor: pointer;
            display: block;
            height: 16px;
            margin: 0 2px;
            transition: $transition-basic;
            width: 16px;

            &.active {
                background: $color-gray;
                width: 56px;
            }

            &:hover {
                background: $color-gray;
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .compatibility {
        .section-title {
            font-size: 3.5rem;
        }

        .intro {
            font-size: 1.8rem;
        }

        .text-extra {
            font-size: 3.5rem;
        }
    }
}

@media screen and (max-width: 840px) {
    .compatibility {
        .text-extra {
            font-size: 3rem;
        }
    }
}

@media screen and (max-width: 720px) {
    .compatibility {
        padding: 20px 0 30px 0;

        .section-title {
            font-size: 3rem;
            margin: 25px 0;
        }

        .intro {
            font-size: 1.6rem;
            margin: 25px 0;
        }

        .text-extra {
            font-size: 2.8rem;
        }

        .slider-trade-list {
            width: 1400px;
    
            &[data-current="1"] {
                transform: translateX(calc(50vw - 140px));
            }
    
            &[data-current="2"] {
                transform: translateX(calc(50vw - 420px));
            }
    
            &[data-current="3"] {
                transform: translateX(calc(50vw - 700px));
            }
    
            &[data-current="4"] {
                transform: translateX(calc(50vw - 980px));
            }
    
            &[data-current="5"] {
                transform: translateX(calc(50vw - 1260px));
            }

            &[data-current="6"] {
                transform: translateX(calc(50vw - 1540px));
            }

            &[data-current="7"] {
                transform: translateX(calc(50vw - 1820px));
            }

            &[data-current="8"] {
                transform: translateX(calc(50vw - 2100px));
            }

            &[data-current="9"] {
                transform: translateX(calc(50vw - 2380px));
            }

            &[data-current="10"] {
                transform: translateX(calc(50vw - 2660px));
            }
    
            .slider-trade-item {
                margin: 10px;
    
                .figure {
                    height: 298px;
                    width: 260px;

                    .title {
                        font-size: 3rem;
                    }

                    .description {
                        font-size: 1.5rem;
                    }

                    .figcaption {
                        padding: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .compatibility {
        padding: 20px 0 40px 0;

        .section-title,
        .intro {
            padding: 0 20px;
        }

        .text-extra {
            font-size: 2.5rem;
            margin: 0;
            padding: 0 20px;

            .btn {
                margin: 20px auto;
                width: 100%;
            }
        }
    }
}
