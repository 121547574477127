.benefits {
    padding: 0 0 150px 0;

    .wrapper {
        position: relative;
        text-align: center;
    }

    .section-title,
    .intro {
        padding: 0 30px;
    }

    .section-title {
        margin-top: 0;
    }

    .intro {
        text-align: center;
    }

    .benefits-list-wrapper {
        margin: 100px 0 50px 0;
        overflow: hidden;
        width: 100%;

        @for $i from 1 through 32 {
            .benefits-list[data-count="#{$i}"] {
                width: calc(20% * #{$i});
            
                .benefits-item {
                    margin: 0 5px;
                    width: calc(100% / #{$i});
                }
            }
        }

        .benefits-list {
            align-items: flex-start;
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            transition: $transition-slider;
        }

        .benefits-item {
            font-size: 1.8rem;
            font-weight: 600;
            margin: 25px 5px;
            padding: 15px;

            .image {
                align-items: center;
                border: 2px solid $color-border-light;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                height: 110px;
                margin: 0 auto;
                width: 110px;

                .icon,
                img {
                    height: 55px;
                    width: 55px;
                }
            }

            p {
                font-size: 2rem;
                text-align: center;

                strong,
                span {
                    display: block;
                }

                strong {
                    margin: 15px 0 15px 0;
                }

                span {
                    font-size: 1.6rem;
                    font-weight: 400;
                }
            }

            .title {
                font-size: 2rem;
                font-weight: 700;
                margin: 15px 0 15px 0;
                text-align: center;
            }

            .short_description p {
                font-size: 1.6rem;
                font-weight: 400;
                text-align: center;
            }
        }

        .prev,
        .next {
            align-items: center;
            background: $color-white;
            border: 2px solid $color-border-light;
            border-radius: 50%;
            display: flex;
            height: 54px;
            justify-content: center;
            position: absolute;
            top: 50%;
            transition: $transition-basic;
            user-select: none;
            width: 54px;

            .icon {
                fill: $color-text-dark;
                height: 13px;
                width: 16px;
            }

            &:active,
            &:focus,
            &:hover {
                background: $color-border-light;
            }

            &.is-hidden {
                opacity: 0;
                pointer-events: none;
                transform: scale(.5);
            }
        }

        .prev {
            left: calc(50% - 760px)
        }

        .next {
            right: calc(50% - 760px)
        }
        
        &.list {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .benefits-list {
                flex-wrap: wrap;
                width: 100%;
    
                &[data-count] {
                    width: 100%;
    
                    .benefits-item {
                        width: calc((100% / 3) - 10px)!important;
                    }
                }
            }

            .benefits-item {
                margin: 30px 5px!important;
                padding: 0 80px;
                text-align: center;
                width: calc((100% / 3) - 10px)!important;
            }

            .prev,
            .next {
                display: none;
            }
        }

        & + .btn {
            display: inline-block;
            line-height: 1.2;
        }
    }

    .text-extra {
        font-size: 3.6rem;
        max-width: 550px;
        margin: 36px auto;
        text-align: center;
    }

    .btn.btn-with-arrow {
        display: block;
        left: 50%;
        margin: 0;
        position: absolute;
        transform: translateX(-50%);
    }
}

.brands.border-bottom + .benefits {
    padding: 75px 0;
}

@media screen and (max-width: 1560px) {
    .benefits .benefits-list-wrapper {
        .prev {
            left: 10px;
        }

        .next {
            right: 10px;
        }
    }
}

@media screen and (max-width: 1240px) {
    .benefits {
        .text-extra {
            font-size: 2.1rem;
        }

        .benefits-list-block {
            .section-title {
                font-size: 4rem;
            }

            .intro {
                font-size: 2rem;
            }
        }

        .benefits-list-wrapper {
            padding: 0 15px;

            .benefits-item {
                margin: 20px 5px !important;
                padding: 0 20px!important;
            }

            @for $i from 1 through 32 {
                .benefits-list[data-count="#{$i}"] {
                    width: calc(25% * #{$i});
                }
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .benefits {
        .intro {
            font-size: 1.8rem;
        }

        .text-extra {
            font-size: 1.8rem;
        }

        .benefits-list-block {
            padding: 40px 0;

            .section-title {
                font-size: 3.4rem;
            }

            .intro {
                font-size: 1.8rem;
            }
        }

        .benefits-list-wrapper {
            margin: 50px 0;

            .benefits-item {
                margin: 15px 5px !important;
            }

            @for $i from 1 through 32 {
                .benefits-list[data-count="#{$i}"] {
                    width: calc((100% / 3) * #{$i});
                }
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .benefits {
        .benefits-list-wrapper {
            margin: 25px 0;
            padding: 0 20px;
            position: relative;

            .benefits-item {
                padding: 0 40px!important;
            }

            @for $i from 1 through 32 {
                .benefits-list[data-count="#{$i}"] {
                    width: calc(50% * #{$i});
                }
            }

            .next {
                right: 0;
            }

            .prev {
                left: 0;
            }

            &.list .benefits-list[data-count] .benefits-item {
                padding: 0 15px!important;
                width: calc(50% - 10px)!important
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .benefits {
        .intro {
            font-size: 1.6rem;
        }
    }
}

@media screen and (max-width: 640px) {
    .benefits {
        .benefits-list-wrapper {
            @for $i from 1 through 32 {
                .benefits-list[data-count="#{$i}"] {
                    width: calc(100% * #{$i});
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .benefits {
        .benefits-list-wrapper.list .benefits-item {
            margin: 15px 5px !important;

            .image {
                height: 80px;
                width: 80px;

                img {
                    height: 35px;
                    width: 35px;
                }
            }
        }

        .btn.btn-with-arrow {
            display: flex;
            justify-content: center;
            margin: 5px 0;
            width: calc(100% - 40px);
        }
    }
}

@media screen and (max-width: 340px) {
    .benefits {
        .benefits-list-wrapper {
            &.list .benefits-list[data-count] .benefits-item {
                width: calc(100% - 10px)!important
            }
        }
    }
}
