.sorting-form {
    display: flex;
    margin-bottom: 20px;
    padding-top: 40px;
    width: 100%;

    .form-item {
        color: $color-white;
        margin: 0 20px;
        position: relative;
        width: calc(50% - 41px);

        label {
            display: block;
            margin: 0 0 10px 0;
            width: auto;
        }

        input#search {
            background: rgba($color-dark, .2);
            border: none;
            color: $color-white;

            &::placeholder {
                color: rgba($color-white, .5);
                font-style: italic;
            }
        }

        .icon.search {
            bottom: 15px;
            fill: $color-white;
            height: 20px;
            position: absolute;
            right: 17px;
            width: 19px;
        }
    }
}
