.main.integration {
    padding: 120px 0 0 0;

    .header-title {
        text-align: center;
        padding-bottom: 75px;
    }

    .section-title + .intro {
        text-align: center;
    }

    .tab {
        margin-top: 0;
        padding-top: 45px;
        position: relative;
        z-index: 1;
    }

    .tab-block {
        min-height: 200px;
        position: relative;
        z-index: 1;
    }

    .tabcontent {
        .intro {
            line-height: 1.25;
            margin: 50px 0 30px 0;
            text-align: center;
        }

        .desc {
            color: $color-white;
            line-height: 1.75;
            margin: 0 auto 40px auto;
            max-width: 520px;
            text-align: center;
            width: 100%;
        }

        .btn {
            height: 50px;
            margin: 30px auto;
            width: 300px
        }
    }

    .integration-graph {
        padding: 0;

        .integration-graph-image {
            display: block;
            margin: 85px auto;
            max-width: 1200px;
            position: relative;
            width: 100%;

            img {
                display: block;
                height: 100%;
                object-fit: contain;
                object-position: center;
                width: 100%;
            }
        }
    }

    .integration-cta {
        .container {
            background: darken($color-white, 3%);
            height: 400px;
            margin: 85px auto;
            padding: 70px;
            position: relative;

            .section-subtitle {
                margin-top: 0;
                text-align: left;
                width: 50%;
            }

            .intro {
                text-align: left;
                width: 40%;
            }

            .links-wrapper {
                width: 50%;

                .btn {
                    display: inline-block;
                    margin: 10px 25px 10px 0;
                    width: 200px;
                    white-space: nowrap;

                    &.btn-outline {
                        border-color: transparent;
                        box-shadow: $box-shadow;

                        &:active,
                        &:focus,
                        &:hover {
                            box-shadow: $box-shadow-hover;
                        }
                    }
                }
            }

            img {
                display: block;
                max-width: 780px;
                position: absolute;
                right: 0;
                top: -20px;
                width: 60%;
            }
        }
    }

    .functions-vertical-list-block {
        border-bottom: 2px solid $color-border-light;
        padding-top: 10px;
    }

    .cms-integration {
        padding: 65px 0 85px 0;

        .wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .section-title {
            margin: 35px 0 0 0;
            width: 100%;
        }

        .intro {
            margin: 20px 0 30px 0;
            width: 100%;
        }

        .logo {
            display: block;
            height: 60px;
            margin: 25px 32px;
            width: auto;
        }
    }
}


@media screen and (max-width: 1300px) {
    .main.integration {
        .integration-cta {
            .container {
                padding: 60px;

                .section-subtitle {
                    font-size: 3.5rem;
                }

                .intro {
                    font-size: 1.9rem;
                    margin: 20px 0;
                }

                .links-wrapper {
                    width: 100%;
                }

                img {
                    top: 50%;
                    transform: translateY(-50%);
                    width: 50%;
                }
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .main.integration {
        .section-title + .intro {
            font-size: 1.8rem;
        }

        .integration-cta {
            .container {
                height: auto;
                margin: 80px 0 50px 0;
                padding: 50px 40px;

                .section-subtitle {
                    font-size: 3rem;
                }

                .intro {
                    font-size: 1.8rem;
                }
            }
        }

        .cms-integration {
            padding: 35px 0 55px 0;

            .logo {
                height: 50px;
                margin: 15px 25px;
            }
        }

        .integration-graph .integration-graph-image {
            margin: 70px auto;
        }
    }
}

@media screen and (max-width: 1000px) {
    .main.integration .tab-block {
        min-height: 260px;
    }
}

@media screen and (max-width: 860px) {
    .main.integration {
        .integration-cta {
            .container {
                height: auto;
                margin: 50px 0 30px 0;
                padding: 40px 30px;

                .section-subtitle {
                    font-size: 2.5rem;
                }

                .intro {
                    font-size: 1.6rem;
                }

                .links-wrapper {
                    .btn {
                        padding: 10px 15px;
                        width: 180px;
                    }
                }
            }
        }

        .cms-integration {
            padding: 20px 0 40px 0;

            .logo {
                height: 40px;
                margin: 10px 15px;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .main.integration {
        .integration-cta {
            .container {
                margin: 50px 0 10px 0;

                .section-subtitle {
                    font-size: 2rem;
                    width: 100%
                }

                .intro {
                    width: 100%
                }

                img {
                    display: none;
                }
            }
        }

        .integration-graph .integration-graph-image {
            margin: 50px auto;
        }
    }
}

@media screen and (max-width: 560px) {
    .main.integration {
        .section-title + .intro {
            font-size: 1.6rem;
        }

        .integration-cta {
            .container {
                padding: 30px;

                .section-subtitle {
                    font-size: 2rem;
                    width: 100%
                }

                .links-wrapper {
                    .btn {
                        padding: 10px 15px;
                        text-align: center;
                        width: 100%;
                    }
                }
            }
        }

        .integration-graph {
            overflow-x: hidden;
            
            .integration-graph-image {
                margin: 50px -25px 0px -25px;
                width: calc(100% + 50px);
            }
        }
    }
}
