.link {
    appearance: none;
    background: transparent;
    color: $color-primary;
    transition: $transition-basic;

    &:active,
    &:focus,
    &:hover {
        color: $color-secondary;
    }

    &.inverse {
        color: $color-secondary-text;

        &:active,
        &:focus,
        &:hover {
            color: $color-primary;
        }
    }

    &.underline {
        color: darken($color-gray, 20%);
        position: relative;

        &:after {
            background: currentColor;
            bottom: -1px;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &:active,
        &:focus,
        &:hover {
            color: $color-primary;
        }
    }

    &.text-color {
        color: $color-text;

        &:active,
        &:focus,
        &:hover {
            color: $color-primary;
        }
    }
}

.btn {
    appearance: none;
    background: $gradient-primary;
    border-radius: 4px;
    border: none;
    color: $color-white;
    cursor: pointer;
    font-weight: 600;
    padding: 11px 25px;
    transition: $transition-basic;

    &:active,
    &:focus,
    &:hover {
        background: $gradient-primary-hover;
        box-shadow: $box-shadow-hover;
    }

    &.btn-outline {
        background: $color-white;
        border: 2px solid $color-border-light;
        color: $color-text;

        &:active,
        &:focus,
        &:hover {
            border: 2px solid transparent;
            box-shadow: $box-shadow-hover;
        }
    }

    &.btn-outline-blue {
        background: transparent;
        border: 2px solid #ABDBFF;
        color: $color-text;

        &:active,
        &:focus,
        &:hover {
            box-shadow: $box-shadow-hover;
        }
    }

    &.btn-green {
        background: $gradient-secondary;

        &:active,
        &:focus,
        &:hover {
            background: $gradient-secondary-hover;
        }
    }

    &.btn-with-icon {
        align-items: center;
        display: flex;
        justify-content: center;

        .icon {
            fill: $color-white;
            height: 18px;
            margin-right: 12px;
            top: 14px;
            width: 18px;
        }
    }

    &.btn-with-arrow {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 16px 25px;

        .icon {
            fill: $color-white;
            height: 13px;
            margin-left: 13px;
            position: relative;
            top: 0;
            transform: none;
            width: 16px;
        }
    }
}

.pagination-block {
    .pagination {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 60px;
        text-align: center;
    
        .pages {
            flex-shrink: 0;
            margin: 0 40px;
            padding-left: 0;
        }
    
        .prev,
        .next {
            color: $color-text;
            font-weight: 700;
            transition: $transition-basic;
    
            &:active,
            &:focus,
            &:hover {
                color: $color-primary;
            }
        }
    
        .page-item {
            background: $color-border-light;
            border-radius: 50%;
            color: $color-text;
            display: inline-block;
            font-family: $font-primary;
            font-size: 1.6rem;
            font-weight: 700;
            height: 45px;
            margin: 0 4px;
            position: relative;
            text-align: center;
            transition: $transition-basic;
            width: 45px;

            a, span {
                align-items: center;
                color: $color-text;
                display: flex;
                height: 100%;
                justify-content: center;
                width: 100%;
            }
    
            &.active {
                background: $gradient-background;
                color: $color-white;

                a, span {
                    color: $color-white;
                }
            }
    
            &:active,
            &:focus,
            &:hover {
                background: $gradient-background;
                color: $color-white;

                a, span {
                    color: $color-white;
                }
            }

            &.disabled {
                &:active,
                &:focus,
                &:hover {
                    background: $color-border-light;
                    color: $color-text;

                    a, span {
                        color: $color-text;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .pagination-block {
        .pages {
            margin: 0 10px;
        }

        .page {
            font-size: 14px;
            height: 30px;
            padding-top: 7px;
            width: 30px;
        }

        .prev,
        .next {
            font-size: 1.4rem;
        }
    }
}
