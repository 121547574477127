.functions {
    border-bottom: 2px solid $color-border-light;
    overflow: hidden;
    padding: 40px 0 60px 0;
    position: relative;
    text-align: center;

    .section-title,
    .intro {
        padding: 0 30px;
    }

    .slider-wrapper {
        padding: 0 60px;
    }

    .slider-functions-block {
        margin: auto;
        max-width: 1360px;
        overflow: hidden;
    }

    .slider-functions-list, .block-functions {
        align-items: stretch;
        margin: 40px 0 60px 0;
        overflow: hidden;
        transition: $transition-slider;
        display: flex;
        user-select: none;
        width: 100%;

        .slider-functions-item {
            align-items: center;
            display: flex;
            flex-direction: column;
            float: left;
            opacity: 0;
            transform: scale(.5);
            transition: all .25s ease-out;

            .icon {
                height: 64px;
                width: 70px;
            }

            .title {
                color: $color-text;
                display: block;
                font-size: 2rem;
                font-weight: 600;
                margin: 30px 0 0 0;
                max-width: 200px;
                text-align: center;
            }

            &.with-options {
                padding-bottom: 60px;
                position: relative;
            }
        }

        &--block-list{
            align-items: stretch;
            flex-wrap: wrap;
            flex-direction: row;
            overflow: unset;
            padding-top: 30px;
            .slider-functions-item{
                opacity: 1;
                transform: unset;
                width: 31%;
                margin: 30px 1% 50px;
                padding: 10px 20px;
                position: relative;
                background: $color-background-green-light;

                .icon-block{
                    position: absolute;
                    top: -60px;
                    background: white;
                    border-radius: 40px;
                    height: 80px;
                    width: 80px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $color-background;
                    .icon{
                        width: 40px;
                        height: 50px;
                        fill: white;
                    }
                }
            }
        }
    }

    .block-functions-list {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        overflow: unset;
        margin: 0 -45px;
        padding-top: 30px;

        .functions-item {
            margin: 20px 45px;
            opacity: 1;
            padding: 10px 20px;
            position: relative;
            transform: unset;
            width: calc((100% / 3) - 90px);

            .icon-block {
                align-items: center;
                border-radius: 50%;
                border: 2px solid $color-border-light;
                display: flex;
                height: 112px;
                justify-content: center;
                margin: 0 auto;
                width: 112px;

                .icon {
                    width: 60px;
                    height: 50px;
                }
            }

            .title {
                color: $color-text;
                font-size: 21px;
                font-weight: 600;
            }

            .short_description {
                color: $color-text;
                font-size: 15px;
                line-height: 1.8;
            }
        }
    }

    .prev,
    .next {
        align-items: center;
        background: $color-white;
        border: 2px solid $color-border-light;
        border-radius: 50%;
        display: flex;
        height: 54px;
        justify-content: center;
        position: absolute;
        top: 50%;
        transition: $transition-basic;
        user-select: none;
        width: 54px;

        .icon {
            fill: $color-text-dark;
            height: 13px;
            width: 16px;
        }

        &:active,
        &:focus,
        &:hover {
            background: $color-border-light;
        }

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
            transform: scale(.5);
        }
    }

    .prev {
        left: calc(50% - 760px)
    }

    .next {
        right: calc(50% - 760px)
    }

    .btn {
        display: inline-block;
        margin: 10px 20px 40px 20px;
        padding: 16px 30px;
    }

    .options {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: absolute;

        small {
            color: $color-gray-footer-light;
            font-size: 14px;
            font-weight: 600;
            display: block;
            margin-bottom: 5px;
            width: 100%;
        }

        .option {
            align-items: center;
            border-radius: 4px;
            display: flex;
            font-size: 12px;
            font-weight: 700;
            justify-content: center;
            margin: 5px;
            padding: 7px 10px;
            text-transform: uppercase;
            width: 100px;

            .icon {
                display: block;
                height: 15px;
                margin-right: 8px;
                width: 15px;
            }

            &.green {
                background: $color-background-green-light;
            }

            &.red {
                background: $color-background-red-light;
            }
        }
    }
}

.slider-functions-list[data-count="#{$functions-slides-count}"] {
    width: calc(100% + (20% * (#{$functions-slides-count} - 5)));

    .slider-functions-item {
        width: calc(100% / #{$functions-slides-count});
    }
}

// Animations and animations RWD
.slider-functions-list[data-count="#{$functions-slides-count}"] {
    @for $j from 0 through $functions-slides-count - 5 {
        &[data-current="#{$j}"] {
            margin-left: calc(#{(-(100% + (20% * ($functions-slides-count - 5))) / $functions-slides-count) * $j});

            @for $k from ($j + 1) through $j + 5  {
                .slider-functions-item[data-slide-index="#{$k}"] {
                    opacity: 1;
                    transform: scale(1);

                    @if $k == $j + 5 or $k == $j + 1 {
                        transition-delay: .25s;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .slider-functions-list[data-count="#{$functions-slides-count}"] {
        @for $j from 0 through $functions-slides-count - 4 {
            &[data-current="#{$j}"] {
                margin-left: calc(#{(-(100% + (25% * ($functions-slides-count - 4))) / $functions-slides-count) * $j});

                @for $k from ($j + 1) through $j + 4  {
                    .slider-functions-item[data-slide-index="#{$k}"] {
                        opacity: 1;
                        transform: scale(1);

                        @if $k == $j + 4 or $k == $j + 1 {
                            transition-delay: .25s;
                        }
                    }
                }
            }
        }
    }

    .slider-functions-list[data-count="#{$functions-slides-count}"] {
        width: calc(100% + (25% * (#{$functions-slides-count} - 4)));
    }
}

@media screen and (max-width: 1040px) {
    .slider-functions-list[data-count="#{$functions-slides-count}"] {
        @for $j from 0 through $functions-slides-count - 3 {
            &[data-current="#{$j}"] {
                margin-left: calc(#{(-(100% + (33.333333% * ($functions-slides-count - 3))) / $functions-slides-count) * $j});

                @for $k from ($j + 1) through $j + 3  {
                    .slider-functions-item[data-slide-index="#{$k}"] {
                        opacity: 1;
                        transform: scale(1);

                        @if $k == $j + 3 or $k == $j + 1 {
                            transition-delay: .25s;
                        }
                    }
                }
            }
        }
    }

    .functions {
        .block-functions-list {
            margin: 0 -20px;

            .functions-item {
                margin: 20px;
                width: calc((100% / 3) - 40px);
            }
        }
    }

    .slider-functions-list[data-count="#{$functions-slides-count}"] {
        width: calc(100% + (33.333333% * (#{$functions-slides-count} - 3)));
    }
}

@media screen and (max-width: 840px) {
    .slider-functions-list[data-count="#{$functions-slides-count}"] {
        @for $j from 0 through $functions-slides-count - 2 {
            &[data-current="#{$j}"] {
                margin-left: calc(#{(-(100% + (20% * ($functions-slides-count - 2))) / $functions-slides-count) * $j});

                @for $k from ($j + 1) through $j + 2  {
                    .slider-functions-item[data-slide-index="#{$k}"] {
                        opacity: 1;
                        transform: scale(1);

                        @if $k == $j + 2 or $k == $j + 1 {
                            transition-delay: .25s;
                        }
                    }
                }
            }
        }
    }

    .functions .block-functions-list .functions-item {
        padding: 0;
    }

    .slider-functions-list[data-count="#{$functions-slides-count}"] {
        width: calc(100% + (50% * (#{$functions-slides-count} - 2)));
    }
}

@media screen and (max-width: 720px) {
    .functions .block-functions-list .functions-item {
        width: calc(50% - 40px);
    }
}

@media screen and (max-width: 640px) {
    .slider-functions-list[data-count="#{$functions-slides-count}"] {
        @for $j from 0 through $functions-slides-count - 1 {
            &[data-current="#{$j}"] {
                margin-left: calc(#{-100% * $j});

                @for $k from ($j + 1) through $j + 1  {
                    .slider-functions-item[data-slide-index="#{$k}"] {
                        opacity: 1;
                        transform: scale(1);

                        @if $k == $j + 1 or $k == $j + 1 {
                            transition-delay: .25s;
                        }
                    }
                }
            }
        }
    }

    .slider-functions-list[data-count="#{$functions-slides-count}"] {
        width: calc(100% * #{$functions-slides-count});
    }
}

// RWD
@media screen and (max-width: 1560px) {
    .functions {
        .prev {
            left: 10px;
        }

        .next {
            right: 10px;
        }

        .slider-functions-block {
            max-width: calc(100vw - 140px);
        }
    }
}

@media screen and (max-width: 1040px) {
    .functions {
        .section-title {
            font-size: 3.5rem;
        }

        .intro {
            font-size: 1.8rem;
        }

        .btn {
            margin: 25px 10px;
        }
    }
}

@media screen and (max-width: 720px) {
    .functions {
        padding: 20px 0 30px 0;

        .section-title {
            font-size: 3rem;
            margin: 25px 0;
        }

        .intro {
            font-size: 1.6rem;
            margin: 25px 0;
        }

        .btn {
            width: 280px;
        }
    }
}

@media screen and (max-width: 560px) {
    .functions {
        padding: 20px 20px 50px 20px;

        &.reservation {
            padding: 20px;
        }

        & + .testimonials {
            margin: 10px 0 0 0;
        }

        .slider-wrapper {
            padding: 0 30px;
        }

        .section-title,
        .intro {
            padding: 0;
        }

        .btn {
            margin: 5px 0;
            width: calc(100% - 40px);
        }

        .block-functions-list {
            margin: 0 -20px;
            
            .functions-item {
                margin: 10px 0;
                width: 100%;

                .icon-block {
                    height: 90px;
                    width: 90px;

                    .icon {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .functions .options .option {
        padding: 7px 12px;
        width: auto;

        &:nth-of-type(1) {
            margin-left: 0;
        }

        &:nth-of-type(2) {
            margin-right: 0;
        }
    }
}
