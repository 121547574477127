.form-item {
    margin: 0 12px;
    width: calc(50% - 25px);

    &.w-100 {
        width: calc(100% - 25px);
    }

    label {
        display: block;
        font-size: 1.8rem;
        margin-bottom: 8px;
        padding-left: 2px;
        width: 100%;
    }

    textarea,
    input[type="text"],
    input[type="email"] {
        border: 1px solid $color-border-light;
        border-radius: 4px;
        height: 40px;
        margin-bottom: 5px;
        outline: none;
        padding: 15px;
        transition: $transition-basic;
        width: 100%;

        &::placeholder {
            color: $color-gray
        }

        &:focus {
            border-color: $color-secondary-text;
            z-index: 1;
        }

        &.is-invalid {
            border: 1px solid $color-error;

            &::placeholder {
                color: $color-error;
            }

            & + .error-text {
                display: block
            }
        }
    }

    textarea {
        height: 120px;
        overflow-y: auto
    }

    .error-text {
        color: $color-error;
        display: block;
        font-size: 12px;
        margin: 0 0 20px 0;

        &.is-hidden {
            display: none;
        }
    }
}

/* Custom checkbox */
.checkbox-container {
    margin: 0 12px;
    position: relative;

    input[type="checkbox"], .checkmark {
        left: 0;
        position: absolute;
        top: -1px;
    }

    input[type="checkbox"] {
        cursor: pointer;
        height: 15px;
        opacity: 0;
        width: 15px;
        z-index: 1;
    }

    .checkmark {
        background: $color-white;
        border: 1px solid $color-border-light;
        border-radius: 2px;
        height: 15px;
        width: 15px;
    }

    input[type="checkbox"]:checked ~ .checkmark {
        background: $color-white;
        border: 1px solid $color-border-light;
        border-radius: 2px;
        height: 15px;
        width: 15px;

        &:after {
            background: $color-secondary-text;
            border-radius: 2px;
            content: '';
            height: 9px;
            left: 2px;
            position: absolute;
            top: 2px;
            width: 9px;
        }
    }

    label {
        color: darken($color-gray, 20%);
        display: inline-block;
        font-size: 11px;
        line-height: 1.6;
        margin: 0 0 0 25px;
    }

    .error-text {
        color: $color-error;
        display: block;
        font-size: 12px;
        margin: 0 0 20px 0;

        &.is-hidden {
            display: none;
        }
    }
}

// Custom single select
.form-item {
    .sort {
        margin-right: 20px;
    }

    &.select-single {
        .select2-container {
            appearance: none;
            color: $color-text;
            cursor: pointer;
            font-size: 1.6rem;
            height: 40px;
            position: relative;
            outline: none;
            width: 100%!important;

            .select2-selection__rendered {
                color: $color-white;
                margin: 0;
                padding: 6px 15px;
            }

            .select2-selection--single {
                background: rgba($color-dark, .2);
                border: none;
                height: 40px;
                outline: none;
                width: 100%;
            }

            .select2-selection__arrow {
                &:after {
                    border: solid $color-white;
                    border-width: 0 1px 1px 0;
                    content: '';
                    height: 8px;
                    position: absolute;
                    right: 20px;
                    top: 14px;
                    transform: rotate(45deg);
                    width: 8px;
                }

                b {
                    display: none;
                }
            }

            .select2-dropdown {
                background-color: #1d3c79;
                box-shadow: $box-shadow-hover;
                border: none;
                border-radius: 4px;
                color: $color-white;
            }

            .select2-results__option {
                padding: 10px;
            }

            .select2-results__option--highlighted[aria-selected] {
                background-color: $color-secondary-text;
            }

            .select2-results__option[aria-selected=true] {
                color: $color-text-dark;
            }
        }
    }
}
