.main.functions {
    padding: 120px 0 0 0;

    .wrapper {
        position: relative;
    }

    .header-title {
        text-align: center;
        padding-bottom: 75px;
    }

    .tab {
        margin-top: 0;
        padding-top: 45px;
    }

    .tabcontent {
        .intro {
            line-height: 1.25;
            margin: 50px 0 30px 0;
            text-align: center;
        }

        .desc {
            color: $color-white;
            line-height: 1.75;
            margin: 0 auto 40px auto;
            max-width: 520px;
            text-align: center;
            width: 100%;
        }

        .btn {
            height: 50px;
            margin: 30px auto;
            width: 300px
        }
    }

    .functions-list-block {
        border-bottom: 2px solid $color-border-light;
        padding: 50px 0;

        &:before {
            background-size: cover;
        }

        .intro {
            margin: 30px 0 10px 0;
            text-align: center;

            &.light {
                color: $color-white;
            }
        }
    }

    .functions-tile-list {
        align-items: center;
        display: flex;
        flex-wrap: wrap;

        .functions-tile-item {
            font-size: 1.8rem;
            font-weight: 600;
            margin: 25px 5px;
            width: calc(25% - 10px);

            .link {
                align-items: center;
                color: $color-white;
                display: flex;
                justify-content: flex-start;
                padding: 25px 15px;
                text-align: left;
                transition: $transition-basic;

                .icon {
                    color: $color-icon;
                    fill: $color-icon;
                    height: 50px;
                    flex-shrink: 0;
                    margin-right: 20px;
                    transition: $transition-basic;
                    width: 55px;
                }

                &:active,
                &:focus,
                &:hover {
                    background: $color-icon;
                    border-radius: 6px;
                    box-shadow: $box-shadow-blue;

                    .icon {
                        fill: $color-white;
                        color: $color-white
                    }
                }
            }
        }
    }

    .tile-list-to-expand {
        overflow: hidden;
        transition: height .5s ease-out;

        &.not-initialized {
            height: 0;
        }

        .functions-tile-item {
            opacity: 0;
            transform: translateZ(0) scale(.6);
            transition: all .4s ease;

            &.is-active {
                opacity: 1;
                transform: translateZ(0) scale(1);
            }
        }        
    }

    .vertical-tile-list {
        padding-bottom: 250px;

        &:before {
            height: calc(100% - 400px);
        }
    }

    .btn.bottom {
        bottom: 115px;
        display: block;
        left: 50%;
        margin: 0;
        position: absolute;
        transform: translateX(-50%);
    }
}

@media screen and (max-width: 1300px) {
    .main.functions {
        .functions-list-block {
            .section-title {
                font-size: 4rem;
            }

            .intro {
                font-size: 2rem;
            }
        }

        .functions-tile-list {
            justify-content: center;

            .functions-tile-item {
                width: calc(25% - 20px);
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .main.functions {
        padding-top: 80px;

        .functions-list-block {
            padding: 40px 0;

            .section-title {
                font-size: 3.4rem;
            }

            .intro {
                font-size: 1.8rem;
            }
        }

        .functions-tile-list {
            justify-content: center;

            .functions-tile-item {
                font-size: 1.6rem;
                margin: 10px 10px;
                max-width: 250px;
                width: calc((100% / 3) - 20px);
            }
        }
    }
}

@media screen and (max-width: 920px) {
    .main.functions {
        .functions-tile-list {
            .functions-tile-item {
                width: calc(50% - 20px);
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .main.functions {
        .tab-block {
            .tabcontent {
                .intro {
                    margin-bottom: 20px;
                }

                .desc {
                    margin-bottom: 20px;
                }
            }
        }

        .functions-list-block {
            padding: 10px 0 30px 0;

            .section-title {
                font-size: 3rem;
            }

            .intro {
                font-size: 1.6rem;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .main.functions {
        padding-top: 60px;

        .header-title {
            padding-bottom: 50px;
        }

        .tab {
            padding: 25px 20px 0 20px;

            .tablinks {
                margin: 0;
                width: 100%;
            }
        }

        .tab-block {
            .tabcontent {
                .btn {
                    width: 100%;
                }

                .three-photo-container {
                    padding-bottom: 0;
                    padding-top: 75%;

                    img {
                        display: none;
                    }

                    .photo-wrapper {
                        width: 100%;

                        img {
                            display: block;
                        }
                    }
                }
            }
        }

        .functions-list-block {
            .section-title {
                font-size: 2.5rem;
            }
        }

        .functions-tile-list {
            justify-content: center;

            .functions-tile-item {
                font-size: 1.4rem;
                width: calc(100% - 20px);

                .link {
                    padding: 15px 5px;

                    .icon {
                        height: 40px;
                        margin-right: 20px;
                        width: 45px;
                    }
                }
            }
        }
    }
}
