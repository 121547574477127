@keyframes loader {
    0% {transform: translateX(-50%) translateY(-50%) rotate(0);}
    100% {transform: translateX(-50%) translateY(-50%) rotate(360deg);}
}

.main.news-single {
    padding-top: 120px;

    .header-title {
        .category-list {
            justify-content: center;
        }

        .category {
            font-size: 1.5rem;

            &:before {
                top: 7px;
            }
        }

        .page-subtitle {
            color: $color-text;
            line-height: 1;
            margin: 15px auto;
            max-width: 640px;
            width: 100%;
        }
    }

    .header-photo {
        padding-top: 130px;
        position: relative;

        .author-block {
            flex-direction: column;
            left: 50%;
            position: absolute;
            top: 10px;
            transform: translateX(-50%);

            .avatar {
                border: 5px solid $color-white;
                height: 90px;
                margin-right: auto;
                margin-top: 20px;
                width: 90px;
            }
        }

        img {
            border-radius: 6px;
            display: block;
            height: auto;
            margin: auto;
            max-width: 1200px;
            width: 100%;
        }
    }

    .content {
        padding-top: 20px;

        .wrapper {
            position: relative;
        }

        .social-block {
            left: -55px;
            position: absolute;
        }

        .description {
            padding: 0 70px;

            p {
                color: $color-text;
                font-size: 1.8rem;
                font-weight: 300;
                line-height: 1.7;
                margin: 30px 0;

                &.intro {
                    font-weight: 600;
                }
            }
        }

        a {
            color: $color-primary;

            &:active,
            &:focus,
            &:hover {
                color: $color-secondary;
            }
        }

        ol {
            color: $color-text;
            padding-left: 18px;

            li {
                font-size: 1.8rem;
                font-weight: 300;
                list-style: decimal;
                line-height: 1.5;
                margin: 8px 0;
                padding-left: 10px;

                ul {
                    padding-left: 25px;

                    li {
                        list-style: none;

                        &:before {
                            background: transparent;
                            border: 1px solid $color-text;
                        }

                        ul {
                            padding-left: 25px;
        
                            li:before {
                                background: $color-text;
                                border: none;
                                height: 1px;
                                top: 12px;
                            }
                        }
                    }
                }
            }
        }

        ul {
            color: $color-text;
            padding-left: 30px;

            li {
                font-size: 1.8rem;
                font-weight: 300;
                line-height: 1.5;
                margin: 8px 0;
                padding-left: 10px;
                position: relative;

                &:before {
                    background: $gradient-background;
                    border-radius: 50%;
                    content: '';
                    height: 6px;
                    left: -10px;
                    position: absolute;
                    top: 9px;
                    width: 6px;
                }

                ul {
                    padding-left: 25px;

                    li:before {
                        background: transparent;
                        border: 1px solid $color-text;

                        ul {
                            padding-left: 25px;
        
                            li:before {
                                background: $color-text;
                                border: none;
                                height: 1px;
                                top: 12px;
                            }
                        }
                    }
                }
            }
        }

        .info,
        .success,
        .warning,
        .neutral,
        .neutral-border {
            display: block;
            font-style: normal;
            font-weight: 400;
            padding: 15px 20px;
            width: 100%;

            a {
                word-break: break-word;
            }
        }

        ol,
        ul {
            &.info,
            &.success,
            &.warning,
            &.neutral,
            &.neutral-border {
                padding: 15px 40px;
            }
        }

        .info {
            background: rgba($color-primary, .8);
            color: $color-white!important;
        }

        .success {
            background: rgba($color-success, .4);
            color: $color-white!important;
        }

        .warning {
            background: rgba($color-warning, .4);
            color: $color-gray-footer!important;
        }

        .neutral {
            background: $color-border-light;
        }
        
        .neutral-border {
            border: 1px solid $color-border;
        }

        blockquote {
            background: rgba($color-border-light, .85);
            color: $color-text;
            display: block;
            font-size: 1.8rem;
            font-weight: 300;
            line-height: 1.8;
            margin: 50px auto;
            padding: 40px 60px;
            position: relative;
            width: 100%;

            span {
                display: block;

                &.author {
                    font-weight: 700;
                    margin-top: 10px;
                    text-transform: uppercase;
                }
            }

            &:before,
            &:after {
                content: '';
                height: 32px;
                position: absolute;
                width: 40px;
            }

            &:before {
                background: url('../images/quote-top.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                left: 32px;
                top: -16px;
            }

            &:after {
                background: url('../images/quote-bottom.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                right: 32px;
                bottom: -16px;
            }
        }

        img {
            display: block;
            height: auto;
            max-width: 100%;
            width: 100%;
        }

        .video-wrapper {
            height: calc(100% - 50px);
            top: 100px;
            width: 100%;

            .video-rwd {
                padding-bottom: 56.5%;
                position: relative;

                iframe {
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }

                &.is-loading {
                    &:before {
                        background: $color-text;
                        content: "";
                        left: 0;
                        height: 100%;
                        opacity: 1;
                        position: absolute;
                        top: 0;
                        transition: all .5s ease-out;
                        width: 100%;
                        z-index: 100;
                    }

                    &:after {
                        animation: loader 1s linear infinite;
                        border: 3px solid $color-white;
                        border-top-color: transparent;
                        border-radius: 50%;
                        content: '';
                        height: 40px;
                        left: 50%;
                        opacity: 1;
                        position: absolute;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        width: 40px;
                        z-index: 102;
                    }
                }

                &.is-hiding {
                    &:before {
                        opacity: 0;
                    }
                }
            }
        }

        .post-gallery {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            img {
                display: block;
                height: auto;
                margin: 15px;
            }
        }

        @for $i from 1 through 32 {
            .post-gallery[data-count="#{$i}"] {
                img {
                    width: calc((100% / 3) - 30px);
                }

                &.post-gallery[data-count="1"] {
                    img {
                        max-width: calc(100% - 30px);
                        width: auto;
                    }
                }

                &.post-gallery[data-count="2"] {
                    img {
                        max-width: calc(50% - 30px);
                        width: auto;
                    }
                }
            }
        }

        .news-pagination {
            align-items: center;
            border-top: 2px solid $color-border-light;
            display: flex;
            justify-content: space-between;
            margin: auto;
            margin-top: 40px;
            max-width: 760px;
            padding: 40px 0 50px 0;
            width: 100%;

            a {
                font-weight: 700;

                &.back {
                    align-items: center;
                    display: flex;

                    .icon {
                        fill: $color-gray;
                        height: 20px;
                        margin-right: 7px;
                        position: relative;
                        width: 20px;
                    }
                }
            }
        }
    }

    .news-block.short {
        border-top: 2px solid $color-border-light;
        width: 100%;

        .page-subtitle {
            color: $color-text;
            margin: 50px 0;
        }

        .news-item {
            width: calc((100% / 3) - 40px);
        }
    }
}

@media screen and (max-width: 1200px) {
    .main.news-single {
        .content {
            .social-block {
                left: 20px;
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .main.news-single {
        padding-top: 80px;

        .content {
            .social-block {
                left: 30px;
            }

            .description {
                padding-right: 40px;
            }

            .post-gallery {
                flex-wrap: wrap;
            }

            @for $i from 1 through 32 {
                .post-gallery[data-count="#{$i}"] {
                    img,
                    &.post-gallery[data-count="1"],
                    &.post-gallery[data-count="2"] {
                        max-width: 280px;
                        width: 100%;
                    }
                }
            }
        }

        .news-block.short {
            .news-list {
                margin: 0 -20px;
            }

            .news-item {
                margin: 0 20px 60px;
                width: calc(50% - 40px);

                &:last-child {
                    display: none;

                }
            }
        }
    }
}

@media screen and (max-width: 760px) {
    .main.news-single {
        .content {
            .news-pagination {
                justify-content: space-around;
                padding: 30px 0;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .main.news-single {
        padding-top: 80px;

        .content {
            .social-block {
                left: 20px;

                i {
                    height: 35px;
                    font-size: 1.4rem;
                    width: 35px;

                    &:before {
                        top: 1px;
                    }
                }
            }

            .description {
                padding: 0 30px 0 60px;

                p {
                    font-size: 1.6rem;
                }


                ol, ul {
                    li {
                        font-size: 1.6rem;
                    }
                }
            }

            blockquote {
                font-size: 1.6rem;
                padding: 20px 30px;

                &:before,
                &:after {
                    height: 20px;
                    width: 28px;
                }

                &:before {
                    left: 15px;
                    top: -10px;
                }

                &:after {
                    bottom: -10px;
                    right: 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .main.news-single {
        .header-title {
            .category {
                font-size: 1.4rem;
            }
        }

        .header-photo {
            padding-top: 120px;

            .author-block {
                font-size: 1.4rem;

                .avatar {
                    height: 80px;
                    width: 80px;
                }
            }
        }

        .content {
            padding-top: 0;

            .wrapper {
                padding-top: 40px;
                position: relative;
            }

            .social-block {
                flex-direction: row;
                left: 15px;
                position: absolute;
                top: 15px;

                a {
                    margin: 0 6px;
                }
            }

            .description {
                padding: 0;
            }

            blockquote {
                font-size: 1.5rem;
                line-height: 1.7;
                margin: 35px auto;
                padding: 15px 25px;

                span.author {
                    margin-top: 5px;
                }

                &:before,
                &:after {
                    height: 16px;
                    width: 24px;
                }

                &:before {
                    left: 15px;
                    top: -10px;
                }

                &:after {
                    bottom: -10px;
                    right: 15px;
                }
            }

            .news-pagination {
                font-size: 1.4rem;
            }
        }

        .news-block.short {
            .news-list {
                margin: 0;
            }

            .news-item {
                margin: 0 0 40px 0;
                width: 100%;

                &:last-child {
                    display: block;
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .main.news-single {
        .content {
            .news-pagination{
                font-size: 12px;
            }
        }
    }
}
