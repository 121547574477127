.brands {
    overflow: hidden;
    padding: 75px 0 20px 0;
    position: relative;
    text-align: center;

    &.border-bottom {
        border-bottom: 2px solid $color-border-light;
        padding: 75px 0;
    }

    .wrapper {
        max-width: 1520px;
    }

    .brands-slider {
        margin: auto;
        max-width: 1280px;
        overflow: hidden;
        position: relative;
    }

    .prev,
    .next {
        align-items: center;
        background: $color-white;
        border: 2px solid $color-border-light;
        border-radius: 50%;
        display: flex;
        height: 54px;
        justify-content: center;
        position: absolute;
        top: 50%;
        transition: $transition-basic;
        user-select: none;
        width: 54px;

        .icon {
            fill: $color-text-dark;
            height: 13px;
            width: 16px;
        }

        &:active,
        &:focus,
        &:hover {
            background: $color-border-light;
        }

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
            transform: scale(.5);
        }
    }

    .prev {
        left: calc(50% - 740px)
    }

    .next {
        right: calc(50% - 740px)
    }

    .brands-list {
        align-items: center;
        display: flex;
        margin-left: -300%;
        transition: $transition-slider;
        user-select: none;

        .brands-item {
            height: 90px;
            margin: 20px;

            .image {
                display: block;
                filter: grayscale(1);
                height: 100%;
                opacity: .75;
                object-fit: contain;
                pointer-events: none;
                width: 100%;

                img {
                    display: block;
                    height: 100%;
                    object-fit: contain;
                    width: 100%;
                }
            }
        }

        &.no-slider {
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;

            .brands-item {
                height: 80px;
                margin: 10px;
            }
        }
    }

    .text-extra {
        font-size: 3.6rem;
        text-align: center;
    }

    .slider-dots {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 10px 0 90px 0;

        span {
            background: $color-gray-footer;
            border: 5px solid $color-white;
            border-radius: 14px;
            cursor: pointer;
            display: block;
            height: 16px;
            margin: 0 2px;
            transition: $transition-basic;
            width: 16px;

            &.active {
                background: $color-gray;
                width: 56px;
            }

            &:hover {
                background: $color-gray;
            }
        }
    }

    @for $i from 1 through 50 {
        .brands-list[data-count="#{$i}"] {
            margin: 0;
            width: calc(100% * #{$i});
        
            .brands-item {
                width: calc(((100% / 5) / #{$i}) - 40px);
            }
        }
    }
}

@media screen and (max-width: 1500px) {
    .brands {
        .prev {
            left: 10px
        }
    
        .next {
            right: 10px
        }
    }
}

@media screen and (max-width: 1300px) {
    .brands {
        .text-extra {
            font-size: 2.1rem;
            line-height: 1.5;
        }

        .prev,
        .next {
            top: 45%
        }
    }
}

@media screen and (max-width: 1040px) {
    .brands {
        padding: 55px 0 10px 0;

        &.border-bottom {
            padding: 55px 0;
        }

        .text-extra {
            font-size: 1.8rem;
        }

        @for $i from 1 through 50 {
            .brands-list[data-count="#{$i}"] {
                .brands-item {
                    width: calc(((100% / 4) / #{$i}) - 40px);
                }
            }
        }

        .slider-dots {
            margin: 10px 0 65px 0
        }
    }
}

@media screen and (max-width: 720px) {
    .brands {
        padding: 40px 0 10px 0;

        &.border-bottom {
            padding: 40px 0;
        }

        .prev,
        .next {
            height: 45px;
            width: 45px;

            .icon {
                width: 14px;
            }
        }
        
        .prev {
            left: 5px
        }
    
        .next {
            right: 5px
        }

        @for $i from 1 through 50 {
            .brands-list[data-count="#{$i}"] {
                .brands-item {
                    width: calc(((100% / 3) / #{$i}) - 40px);
                }
            }
        }

        .brands-list.no-slider {
            .brands-item {
                width: calc((100% / 3) - 20px);
            }
        }

        .slider-dots {
            margin: 10px 0 50px 0
        }
    }
}

@media screen and (max-width: 640px) {
    .brands {
        .brands-list .brands-item {
            margin: 10px;
        }

        @for $i from 1 through 50 {
            .brands-list[data-count="#{$i}"] {
                .brands-item {
                    width: calc((50% / #{$i}) - 20px);
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .brands {
        padding: 30px 0 10px 0;

        &.border-bottom {
            padding: 30px 0;
        }

        .wrapper {
            padding: 0 40px;
        }

        .brands-list.no-slider {
            .brands-item {
                width: calc(50% - 20px);
            }
        }
    }
}
