.vertical-tile-list {
    padding: 100px 0;
    position: relative;

    &:before {
        background: $color-border-light;
        content: '';
        display: block;
        height: calc(100% - 520px);
        left: 50%;
        position: absolute;
        top: 260px;
        transform: translateX(-50%);
        width: 3px;
        z-index: 0;
    }

    .vertical-tile-item {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: -100px 0;
        padding: 100px 0;
        position: relative;
        z-index: 1;

        .step-icon {
            align-items: center;
            background: $color-white;
            border-radius: 50%;
            border: 2px solid $color-border-light;
            display: flex;
            flex-shrink: 0;
            height: 130px;
            justify-content: center;
            margin: 0 80px;
            position: relative;
            transition: $transition-basic;
            width: 130px;

            .icon {
                height: 63px;
                transition: $transition-basic;
                width: 65px;
            }

            &:active,
            &:focus,
            &:hover {
                background: $color-icon;
                border: 2px solid transparent;
                box-shadow: $box-shadow-blue;

                .icon {
                    fill: $color-white;
                }
            }
        }

        .text-wrapper {
            width: calc(50% - 145px);

            .page-subtitle {
                text-align: left;
            }

            .description {
                font-size: 2.1rem;
                line-height: 1.5;
                text-align: left;
            }

            .link.inverse {
                font-size: 2.1rem;
                font-weight: 600;
            }
        }

        .photo-wrapper {
            background: darken($color-white, 3%);
            margin-bottom: 30px;
            padding: 30px;
            position: relative;
            width: calc(50% - 145px);

            img {
                display: block;
                height: auto;
                width: 100%;
            }

            .zoom-in {
                align-items: center;
                background: $color-white;
                border-radius: 4px;
                bottom: -20px;
                box-shadow: $box-shadow-bottom;
                color: $color-text;
                cursor: pointer;
                display: flex;
                height: 40px;
                font-weight: 600;
                justify-content: center;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                width: 120px;

                .icon {
                    height: 14px;
                    width: 14px;
                    fill: $color-text;
                    margin-right: 10px;
                }

                &:active,
                &:focus,
                &:hover {
                    box-shadow: $box-shadow-hover;
                }
            }
        }

        &:nth-of-type(2n+2) {
            flex-direction: row-reverse;
        }
    }
}

@media screen and (max-width: 1300px) {
    .vertical-tile-list {
        .vertical-tile-item {
            .text-wrapper {
                width: calc(50% - 80px);

                .page-subtitle {
                    font-size: 4rem;
                }

                .description {
                    font-size: 1.8rem;
                }

                .link.inverse {
                    font-size: 1.8rem;
                }
            }

            .step-icon {
                margin: 0 60px;
            }

            .photo-wrapper {
                width: calc(50% - 80px);
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .vertical-tile-list {
        .vertical-tile-item {
            // margin: 70px 0;

            .text-wrapper {
                width: calc(50% - 100px);

                .page-subtitle {
                    font-size: 3.5rem;
                    margin: 25px 0;
                }

                .description {
                    font-size: 1.6rem;
                }

                .link.inverse {
                    font-size: 1.6rem;
                }
            }

            .step-icon {
                height: 100px;
                margin: 0 40px;
                width: 100px;

                .icon {
                    height: 50px;
                    width: 50px;
                }
            }

            .photo-wrapper {
                width: calc(50% - 100px);
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .vertical-tile-list {
        .vertical-tile-item {
            // margin: 60px 0;

            .text-wrapper {
                width: calc(50% - 70px);

                .page-subtitle {
                    font-size: 3.5rem;
                    margin-top: 0;
                }

                .description {
                    font-size: 1.4rem;
                }

                .link.inverse {
                    font-size: 1.4rem;
                }
            }

            .step-icon {
                height: 70px;
                margin: 0 30px;
                width: 70px;

                .icon {
                    height: 30px;
                    width: 30px;
                }
            }

            .photo-wrapper {
                padding: 35px 15px;
                width: calc(50% - 70px);
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .vertical-tile-list {        
        padding-top: 0;

        &:before {
            display: none;
        }

        .vertical-tile-item {
            flex-wrap: wrap;
            margin: 60px 0;
            padding-bottom: 0;
            padding-top: 50px;

            .text-wrapper {
                order: 2;
                width: 100%;

                .page-subtitle {
                    font-size: 3rem;
                    padding-left: 90px;
                }
            }

            .step-icon {
                left: 0;
                margin: 0;
                position: absolute;
                top: 30px;
            }

            .photo-wrapper {
                margin-top: 20px;
                order: 3;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .vertical-tile-list {
        .vertical-tile-item {
            margin: 40px 0;

            .text-wrapper {
                .page-subtitle {
                    font-size: 2rem;
                    padding-left: 70px;
                }
            }

            .step-icon {
                height: 50px;
                top: 35px;
                width: 50px;

                .icon {
                    height: 20px;
                    width: 20px;
                }
            }

            .photo-wrapper {
                padding: 20px 10px;
            }
        }
    }
}
