.functions.reservation {
    .wrapper {
        padding: 0;
    }
}

.reservation-methods {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    .method {
        margin: 60px 20px;
        padding: 75px 45px 0 45px;
        position: relative;
        width: calc(50% - 40px);

        &:before {
            background: $color-background-blue-light;
            border-radius: 6px;
            content: '';
            display: block;
            height: 500px;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
        }

        &-icon {
            align-items: center;
            background: linear-gradient(180deg, rgba(#89D22A, 1) 0%, rgba(#34AB44, 1) 100%);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            height: 112px;
            left: 50%;
            position: absolute;
            top: -60px;
            transform: translateX(-50%);
            width: 112px;
            z-index: 1;

            img {
                display: block;
                height: 55px;
                width: 55px;
            }
        }

        &-title {
            color: $color-text;
            font-size: 40px;
            font-weight: 700;
            position: relative;
            z-index: 1;
        }

        &-text {
            color: $color-text;
            font-size: 18px;
            max-width: 450px;
            margin: 0 auto;
            position: relative;
            z-index: 1;
        }

        .btn {
            margin: 20px auto 45px auto;
            max-width: 200px;
            position: relative;
            z-index: 1;
            width: 100%;
        }

        &-image {
            background: $color-white;
            border-radius: 6px;
            box-shadow: 0 30px 30px rgba($color-dark, .08);
            display: block;
            height: 385px;
            padding: 25px 40px;
            position: relative;
            z-index: 1;
            width: 100%;

            img {
                display: block;
                height: auto;
                object-fit: contain;
                object-position: center;
                width: 100%;
            }
        }

        &.horiz {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 50px 0;
            padding: 80px 40px 80px 80px;
            width: 100%;

            &:before {
                height: 100%;
                width: 64%;
            }
            
            .method-text-wrapper {
                max-width: 410px;
                padding-right: 40px;
                text-align: left;
                width: 35%;

                .method-icon {
                    left: 0;
                    position: relative;
                    top: 0;
                    transform: none;
                }

                .method-title {
                    margin: 30px 0;
                }
                
                .method-text {
                    line-height: 1.5;
                    max-width: 100%;
                    margin: 0;
                }
            }

            .method-image {
                background: none;
                height: auto;
                margin-left: auto;
                max-width: 745px;
                padding: 0;
                width: 65%;
            }

            &:nth-of-type(even) {
                flex-direction: row-reverse;
                padding: 80px 80px 80px 40px;

                &:before {
                    left: inherit;
                    right: 0;
                }

                .method-image {
                    margin: 0;
                }

                .method-text-wrapper {
                    padding-right: 0;
                    padding-left: 40px;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .reservation-methods {
        .method {
            padding: 75px 40px 0 40px;
        }

        .method-title {
            font-size: 36px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .reservation-methods {
        .method {
            padding: 60px 40px 0 40px;
        }

        .method-title {
            margin: 15px 0 20px 0;
        }

        .method-image {
            height: auto;
            padding: 20px;
        }
    }
}

@media screen and (max-width: 1040px) {
    .reservation-methods {
        margin: 0 -10px;

        .method {
            margin: 60px 10px 40px 10px;
            padding: 50px 20px 0 20px;
            width: calc(50% - 20px);

            &.horiz {
                padding: 60px 40px 60px 40px;

                .method-text-wrapper,
                .method-image {
                    width: 50%;
                }

                &:nth-of-type(even) {
                    padding: 60px 40px 60px 40px
                }
            }
        }

        .method-icon {
            height: 100px;
            top: -50px;
            width: 100px;

            img {
                height: 50px;
                width: 50px;
            }
        }

        .method-title {
            font-size: 34px;
        }

        .method-text br {
            display: none;
        }

        .method-image {
            height: auto;
            padding: 20px;
        }
    }
}

@media screen and (max-width: 840px) {
    .reservation-methods {
        .method {
            &.horiz {
                flex-wrap: wrap;
                padding: 0 40px;

                &:before {
                    height: 80%;
                    top: 50px;
                    width: 100%;
                }

                .method-text-wrapper {
                    margin: 0 auto;
                    padding: 0 0 30px 0;
                    text-align: center;

                    .method-icon {
                        margin: 0 auto;
                    }
                }
                
                .method-text-wrapper,
                .method-image {
                    width: 100%;
                }
                
                &:nth-of-type(even) {
                    padding: 0 40px;

                    .method-text-wrapper {
                        padding: 0 0 30px 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .reservation-methods {
        .method {
            padding: 40px 20px 0 20px;

            &:before {
                height: 400px;
            }
        }

        .method-icon {
            height: 80px;
            top: -40px;
            width: 80px;

            img {
                height: 35px;
                width: 35px;
            }
        }

        .method-title {
            font-size: 26px;
        }

        .method-text {
            font-size: 16px;
        }

        .method-image {
            padding: 15px;
        }
    }
}

@media screen and (max-width: 560px) {
    .reservation-methods {
        .method {
            width: calc(100% - 20px)
        }
    }
}
