.main.news,
.main.news-single {
    .news-block {
        width: calc((100% / 3) * 2);

        .news-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -20px;
        }

        .news-item {
            margin: 0 20px 60px 20px;
            width: calc(50% - 40px);

            &.w-100 {
                width: calc(100% - 40px);
            }

            .news-title {
                font-size: 2.6rem;
                font-weight: 700;
                line-height: 1.15;
                margin: 0;

                .link {
                    color: $color-text;

                    &:active,
                    &:focus,
                    &:hover {
                        color: $color-primary;
                    }
                }
            }

            .description {
                color: $color-text;
                font-size: 1.8rem;
                line-height: 1.5;
                margin: 18px 0;
            }
        }
    }

    .photo-wrapper {
        border-radius: 6px;
        cursor: pointer;
        display: block;
        overflow: hidden;
        padding-bottom: 50%;
        position: relative;
        width: 100%;

        img {
            display: block;
            height: auto;
            left: 50%;
            min-height: 100%;
            min-width: auto;
            position: absolute;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            transition: $transition-basic;
            width: 100%;
            will-change: transform;
        }

        &:active,
        &:focus,
        &:hover {
            img {
                transform: translateX(-50%) translateY(-50%) scale(1.1);
            }
        }
    }

    .category-list {
        display: flex;
        margin: 20px 0 0 0;

        .category {
            cursor: pointer;
            display: inline-block;
            font-size: 1.3rem;
            font-weight: 600;
            margin: 10px 10px;
            position: relative;
            text-transform: uppercase;

            .link {
                color: $color-secondary-text;

                &:active,
                &:focus,
                &:hover {
                    color: $color-primary;
                }
            }

            &:before {
                background: lighten($color-gray, 5%);
                border-radius: 2px;
                content: '';
                display: block;
                height: 4px;
                left: -11px;
                position: absolute;
                top: 5px;
                transition: $transition-basic;
                width: 4px;
            }

            &:first-child {
                margin-left: 0;

                &:before {
                    display: none;
                }
            }
        }
    }

    .author-block {
        align-items: center;
        display: flex;

        .avatar {
            border-radius: 50%;
            display: block;
            height: 47px;
            margin-right: 15px;
            width: 47px;
        }

        .desc {
            display: block;
            margin: 5px 0;

            &.author {
                font-weight: 600;

                .link {
                    color: $color-text;

                    &:active,
                    &:focus,
                    &:hover {
                        color: $color-primary;
                    }
                }
            }

            &.date {
                color: darken($color-gray, 5%);
            }
        }
    }
}

.main.news {
    padding-top: 115px;

    .header-title {
        padding: 10px 40px 35px 40px;
    }

    .sorting-form {
        .form-item {
            margin: 0;
            width: calc(100% / 3);

            &:nth-of-type(1) {
                padding-right: 20px;
            }

            &:nth-of-type(2) {
                padding-left: 20px;
            }

            &.search {
                padding-left: 40px;
            }
        }
    }

    .news-content {
        position: relative;

        &:before {
            background: $gradient-background;
            content: '';
            height: 275px;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0
        }

        .wrapper {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            z-index: 1
        }
    }

    .sidebar-nav {
        background: darken($color-white, 6%);
        flex-shrink: 0;
        margin: 0 0 0 40px;
        padding: 30px;
        width: calc((100% / 3) - 40px);

        .title {
            color: $color-text;
            font-size: 2.6rem;
            line-height: 2;
            margin: 15px 0;
        }

        .photo-wrapper {
            flex-shrink: 0;
            margin-right: 20px;
            padding-bottom: 77px;
            width: 115px;
        }

        .news-title {
            font-size: 1.6rem;
            margin: 5px 0;

            .link {
                color: $color-text;

                &:active,
                &:focus,
                &:hover {
                    color: $color-primary;
                }
            }
        }

        .date {
            color: darken($color-gray, 15%);
            font-size: 1.4rem;
        }

        .sidebar-item {
            align-items: center;
            display: flex;
            margin: 20px 0;
        }
    }
}

@media screen and (max-width: 1300px) {
    .main.news,
    .main.news-single {
        .news-block {
            .news-item {
                .news-title {
                    font-size: 2.2rem;
                }

                .description {
                    font-size: 1.6rem;
                }
            }
        }
    }

    .main.news {
        .sidebar-nav {
            padding: 20px;

            .title {
                font-size: 2.2rem;
            }

            .sidebar-item {
                flex-wrap: wrap;

                .photo-wrapper {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .main.news,
    .main.news-single {
        .news-block {
            width: calc(100% - 315px);

            .news-list {
                margin: 0;
            }

            .news-item {
                margin: 0 0 60px 0;
                width: 100%;

                &.w-100 {
                    width: 100%;
                }
            }
        }

        .sidebar-nav {
            width: 274px;

            .title {
                font-size: 2rem;
            }
        }
    }

    main.news {
        padding-top: 60px;

        .sorting-form {
            flex-wrap: wrap;
            padding-top: 30px;

            .form-item {
                width: 50%;

                &:nth-of-type(1) {
                    padding-right: 10px;
                }

                &:nth-of-type(2) {
                    padding-left: 10px;
                }

                &.search {
                    margin-top: 20px;
                    padding-left: 0;
                    width: 100%;
                }

                &.select-single .select2-container {
                    font-size: 1.4rem;
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .main.news,
    .main.news-single {
        .news-block {
            width: 100%;

            .news-list {
                margin: 0;
            }

            .news-item {
                margin: 0 0 60px 0;
                width: 100%;

                &.w-100 {
                    width: 100%;
                }
            }
        }

        .sidebar-nav {
            margin-left: 0;
            margin-bottom: 40px;
            width: 100%;

            .title {
                font-size: 1.8rem;
                margin: 10px 0;
            }

            .sidebar-list {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -20px;

                .sidebar-item {
                    margin: 20px;
                    width: calc(50% - 40px);
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .main.news,
    .main.news-single {
        .category-list {
            margin-top: 10px;
        }

        .news-block {
            .news-item {
                margin-bottom: 40px;

                .news-title {
                    font-size: 1.8rem;
                }

                .description {
                    font-size: 1.4rem;
                    margin: 15px 0;
                }

                .author-block {
                    font-size: 1.4rem;
                }
            }
        }
    }

    .main.news {
        padding-top: 60px;

        .sorting-form {
            flex-wrap: wrap;
            padding-top: 0;

            .form-item {
                margin-top: 15px;
                width: 100%;

                &.search {
                    padding-left: 0;
                }

                &:nth-of-type(1) {
                    padding-right: 0;
                }

                &:nth-of-type(2) {
                    padding-left: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 440px) {
    .main.news,
    .main.news-single {
        .sidebar-nav {
            .sidebar-list {
                margin: 0;

                .sidebar-item {
                    margin: 10px 0 15px 0;
                    width: 100%;
                }
            }
        }
    }
}
