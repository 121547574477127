.cta-block {
  border-radius: 6px;
  max-width: 1280px;
  overflow: hidden;
  padding: 0;
  position: relative;

  .text-wrapper {
    padding: 85px;
    position: relative;
    width: 55%;
    z-index: 1;

    .page-subtitle {
      font-size: 4.5rem;
      line-height: 1.12;
      text-align: left;
    }

    .description {
      font-size: 2.1rem;
      margin-bottom: 55px;
    }

    .btn.btn-with-arrow {
      width: 245px;
    }
  }

  .cta-bg-image {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

@media screen and (max-width: 1200px) {
  .cta-block {
    border-radius: 0;
    
    .text-wrapper {
      padding: 50px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .cta-block .text-wrapper {
    padding: 50px;

    .page-subtitle {
      font-size: 3.5rem;
    }

    .intro {
      font-size: 1.9rem;
    }
  }
}

@media screen and (max-width: 840px) {
  .cta-block .text-wrapper {
    padding: 30px;
    width: 60%;

    .upper-text {
      margin-bottom: 20px;
    }

    .page-subtitle {
      margin: 20px 0;
    }

    .intro {
      margin: 20px 0 30px;
    }
  }
}

@media screen and (max-width: 720px) {
  .cta-block .text-wrapper {
    .page-subtitle {
      font-size: 3rem;
    }

    .intro {
      font-size: 1.8rem;
    }
  }
}

@media screen and (max-width: 560px) {
  .cta-block {
    .text-wrapper {
      width: 75%;

      .page-subtitle {
        font-size: 2.4rem;
      }

      .intro {
        font-size: 1.6rem;
      }
    }

    .cta-bg-image {
      object-position: 35%;
    }
  }
}

@media screen and (max-width: 450px) {
  .cta-block {
    .cta-bg-image {
      object-position: 45%;
    }
  }
}

@media screen and (max-width: 420px) {
  .cta-block {
    background: #e9eaee;

    .text-wrapper {
      width: 100%;
    }
    
    .cta-bg-image {
      display: none
    }
  }
}