@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-Regular.woff2') format('woff2'),
      url('./fonts/SourceSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-Light.woff2') format('woff2'),
      url('./fonts/SourceSansPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
      url('./fonts/SourceSansPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-Bold.woff2') format('woff2'),
      url('./fonts/SourceSansPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
