.system {
    background: $gradient-background;
    color: $color-white;
    overflow: hidden;
    position: relative;

    &:before {
        background-image: url('../images/bg-blue-mask.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
    }

    .tab-block {
        position: relative;
        z-index: 1;

        .tabcontent {
            display: block;
            left: 0;
            margin-left: 15%;
            min-height: 725px;
            opacity: 0;
            padding: 150px 40px;
            pointer-events: none;
            position: absolute;
            top: 0;
            transition: all .5s ease-out;
            width: 100%;
            z-index: 1;

            &.active {
                opacity: 1;
                position: relative;
                pointer-events: auto;

                .text-wrapper {
                    opacity: 1;
                }

                .screens {
                    opacity: 1;
                    transform: translateX(0) translateY(-50%);
                }
            }

            .text-wrapper {
                max-width: 610px;
                opacity: 0;
                position: relative;
                transition: all .25s ease-out;
                width: 50%;

                .slider-nav {
                    bottom: 6px;
                    height: 43px;
                    left: 320px;
                    position: absolute;
                    top: auto;
            
                    .prev,
                    .next {
                        cursor: pointer;
                        display: inline-block;
                        padding: 20px;
                        position: relative;
                        z-index: 2;
            
                        &:before {
                            border: solid $color-white;
                            content: '';
                            display: block;
                            height: 20px;
                            position: absolute;
                            top: 13px;
                            transform: rotate(45deg);
                            width: 20px;
                        }
                    }
            
                    .prev {
                        &:before {
                            border-width: 0 0 2px 2px;
                            left: 15px;
                        }
                    }
            
                    .next {
                        &:before {
                            border-width: 2px 2px 0 0;
                            left: 9px;
                        }
                    }
                }
            }

            .screens {
                display: block;
                height: 600px;
                max-width: 950px;
                opacity: 0;
                position: absolute;
                right: 35%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                transition: all .75s ease-out;
                width: 45%;
                z-index: 0;

                img {
                    display: block;
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                    width: 100%;
                }
            }

            &[data-id="system-rwd"] {
                .screens {
                    right: 32%
                }
            }

            &[data-id="system-discount-round"] {
                .screens {
                    right: 40%;
                    top: calc(50% - 65px);
                }
            }

            &[data-id="rezerwacje-dni"],
            &[data-id="system-podglad-wydarzen"] {
                .screens {
                    max-width: 700px;
                }
            }

            &[data-id="system-support"] {
                .screens {
                    right: 15%;
                    height: 500px;
                }
            }
        }
    }

    .page-subtitle {
        color: $color-white;
        text-align: left;
    }

    .description {
        font-size: 2.1rem;
        margin-bottom: 55px;
    }

    .btn.btn-with-arrow {
        width: 280px;
    }

    .tab {
        background: linear-gradient(90deg, rgba(20,47,101,1) 0%, rgba(42,82,162,1) 100%);
        border-top: 2px solid rgba($color-white, .1);
        display: flex;
        height: 160px;
        justify-content: center;
        margin-top: 0;
        padding: 0 60px;
        position: relative;
        z-index: 1;

        .tablinks {
            align-items: center;
            color: rgba($color-white, .5);
            display: flex;
            font-weight: 600;
            height: 100%;
            justify-content: center;
            line-height: 1.2;
            margin: 0;
            position: relative;
            text-align: left;
            width: calc(100% / 6);

            &:after {
                background: $color-white;
                border-radius: 2px;
                content: '';
                height: 4px;
                left: 50%;
                position: absolute;
                top: -3px;
                transform: translateX(-50%);
                width: 0;
            }

            &:before {
                display: none;
            }

            .description {
                font-size: 1.8rem;
                margin: 0;
            }

            .icon {
                fill: rgba($color-white, .5);
                flex-shrink: 0;
                height: 48px;
                margin-right: 20px;
                width: 40px;
            }

            &.active,
            &:active,
            &:focus,
            &:hover {
                border-bottom: none;
                color: $color-white;

                &:after {
                    width: 98%;
                }

                .icon {
                    fill: $color-white;
                }
            }
        }
    }

    .watermark {
        display: block;
        height: 75%;
        left: -120px;
        pointer-events: none;
        position: absolute;
        top: 50px;
        transition: all .25s ease-out;
        width: 487px;
        z-index: 0;

        .icon {
            height: 100%;
            opacity: .125;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }

        &.is-hidden {
            opacity: 0;
            transform: scale(.75);
        }
    }
}

@media screen and (max-width: 2200px) {
    .system {
        .tab-block {
            .tabcontent {
                .screens {
                    right: 30%
                }

                &[data-id="system-discount-round"] {
                    .screens {
                        right: 30%
                    }
                }

                &[data-id="system-rwd"] {
                    .screens {
                        right: 25%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1920px) {
    .system {
        .tab {
            .tablinks {
                padding: 25px;
            }
        }

        .tab-block {
            .tabcontent {
                .screens {
                    right: 20%;
                }

                &[data-id="system-rwd"] {
                    .screens {
                        right: 17%;
                    }
                }

                &[data-id="system-discount-round"] {
                    .screens {
                        right: 20%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1700px) {
    .system {
        .tablinks-wrapper {
            padding: 0 40px;
        }

        .tab {
            height: 140px;

            .tablinks {
                padding: 25px 15px;

                .icon {
                    height: 43px;
                    margin-right: 10px;
                    width: 35px;
                }

                .description {
                    font-size: 1.6rem;
                }
            }
        }

        .tab-block {
            .tabcontent {
                margin-left: 100px;
                min-height: 650px;
                padding: 100px 40px;

                .upper-text {
                    font-size: 15px;
                    margin-bottom: 25px;
                }

                .page-subtitle {
                    font-size: 4rem;
                }

                .intro {
                    font-size: 1.8rem;
                }

                .description {
                    font-size: 1.8rem;
                    margin-bottom: 40px;
                }

                .screens {
                    max-width: 800px;
                    right: 50px;
                }

                &[data-id="system-discount"] {
                    .screens {
                        right: -50px;
                    }
                }

                &[data-id="system-rwd"] {
                    .screens {
                        right: -50px;
                    }
                }

                &[data-id="system-discount-round"] {
                    .screens {
                        right: 10%;
                    }
                }

                &[data-id="system-card"] {
                    .screens {
                        right: 10%;
                    }
                }

                &[data-id="system-support"] {
                    .screens {
                        right: 50px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .system .tab-block .tabcontent[data-id=system-card] .screens {
        right: 6%
    }
}

@media screen and (max-width: 1300px) {
    .system {
        .tablinks-wrapper {
            padding: 0 20px;
        }

        .tab {
            height: 140px;

            .tablinks {
                padding: 20px 10px;

                .icon {
                    height: 40px;
                    width: 32px;
                }

                .description {
                    font-size: 14px;

                    br {
                        display: none;
                    }
                }
            }
        }

        .tab-block {
            .tabcontent {
                min-height: 500px;

                .screens {
                    height: 450px;
                }

                &[data-id="system-card"] {
                    .screens {
                        height: 350px;
                    }
                }

                &[data-id="rezerwacje-dni"] {
                    .screens {
                        max-width: 500px;
                    }
                }

                &[data-id="system-discount-round"] .screens {
                    right: 5%;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .system {
        .tab-block {
            .tabcontent {
                &[data-id="rezerwacje-dni"] {
                    .screens {
                        max-width: 500px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .system {
        .tablinks-wrapper {
            padding: 0 20px;
        }

        .tab {
            border: 1px solid rgba($color-white, .1);
            flex-wrap: wrap;
            height: auto;
            position: relative;

            &:before,
            &:after {
                background: rgba($color-white, .1);
                content: '';
                height: 1px;
                left: 0;
                position: absolute;
                top: 79px;
                width: 100%;
            }

            &:before {
                top: 79px;
            }

            &:after {
                top: 158px;
            }

            .tablinks {
                height: 80px;
                padding: 10px;
                width: calc(100% / 3);
            }
        }

        .tab-block {
            .tabcontent {
                margin-left: 0;
                padding: 80px 40px;

                .text-wrapper {
                    .slider-nav {
                        left: 300px;

                        .prev,
                        .next {
                            padding: 18px;
                        }
                    }
                }

                .page-subtitle {
                    font-size: 3.5rem;
                }

                .intro,
                .description {
                    font-size: 16px;
                    margin: 20px 0;
                }

                .screens {
                    right: -50px;
                }

                &[data-id="system-discount"] {
                    .screens {
                        right: -150px;
                    }
                }

                &[data-id="system-discount-round"] {
                    .screens {
                        right: 0;
                    }
                }

                &[data-id="system-card"] {
                    .screens {
                        right: 10px;
                    }
                }

                &[data-id="system-support"] {
                    .screens {
                        right: 0px;
                        height: 350px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .system {
        .tab-block {
            .tabcontent {
                min-height: 450px;

                .text-wrapper {
                    width: 100%
                }

                .screens {
                    display: none;
                }
            }
        }

        .watermark {
            display: none;
        }
    }
}

@media screen and (max-width: 720px) {
    .system {
        .tab-block {
            .tabcontent {
                padding: 60px 40px;

                .text-wrapper {
                    .upper-text {
                        font-size: 13px;
                        margin-bottom: 15px;
                    }

                    .page-subtitle {
                        font-size: 3rem;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .system {
        .tab {
            flex-wrap: wrap;
            height: auto;
            position: relative;

            &:before,
            &:after {
                background: rgba($color-white, .1);
                content: '';
                height: 1px;
                left: 0;
                position: absolute;
                width: 100%;
            }

            &:before {
                top: 79px;
            }

            &:after {
                top: 159px;
            }

            .tablinks {
                width: calc(100% / 2);
            }
        }

        .tab-block {
            .tabcontent {
                padding: 60px 20px;

                .text-wrapper {
                    .btn {
                        width: 100%;
                    }

                    .slider-nav {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .system {
        .tab {
            justify-content: flex-start;

            .tablinks {
                font-size: 1.2rem;
                width: calc(100% / 6);

                .description {
                    display: none;
                }

                .icon {
                    margin: 0;
                    height: 36px;
                    width: 28px;
                }
            }
        }
    }
}
