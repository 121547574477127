/*
 * Fonts
 */
$font-primary: 'Source Sans Pro', sans-serif;

/*
 * Colors
 */
$color-primary: #56b7ff;
$color-secondary: #99d945;
$color-dark: #000;
$color-gray: #ccc;
$color-gray-footer: #7e828c;
$color-gray-footer-light: #7C8595;
$color-white: #fff;
$color-shadow: rgba(0, 0, 0, .075);
$color-error: #ff0000;
$color-warning: #ffa602;
$color-success: #008000;
$color-icon: #2ea2f8;
$color-icon-superlight: #abdbff;
$color-icon-light: #a1b7e5;
$color-icon-dark: #2f5bb4; //linear-gradient(to right, #2f5bb4 0%, #163571 100%)

$color-text: #1f3359;
$color-text-dark: #0d1626;
$color-text-light: #bac7e6;
$color-secondary-text: #5aaf34;
$color-secondary-text-light: #b9ff89;

$color-border: #908e98;
$color-border-light: #ededed;
$color-border-dark: #343434;

$color-background: #1b3c7f;
$color-background-dark: #26334b;
$color-background-overlay: #071839;
$color-background-green-light: #d5eac3;
$color-background-red-light: #fcdae2;
$color-background-blue-light: #F1F9FF;

$color-social-fb: #3b5998;
$color-social-twitter: #55acee;
$color-social-instagram: #e95950;
$color-social-linkedin: #007bb5;
$color-social-pinterest: #cb2027;
$color-social-pocket: #ed4055;
$color-social-rss: #f60;
$color-social-snapchat: #fffc00;
$color-social-whatsapp: #4dc247;
$color-social-youtube: #b00;
$color-social-googleplus: #dd4b39;

/*
 * Gradients
 */
$gradient-primary: linear-gradient(to top, #1991eb 0%, #2ea1f8 100%);
$gradient-primary-hover: linear-gradient(to top, #1991eb 40%, #2ea1f8 100%);
$gradient-secondary: linear-gradient(to top, #34ab43 0%, #76b228 100%);
$gradient-secondary-hover: linear-gradient(to top, #34ab43 40%, #76b228 100%);
$gradient-background: linear-gradient(90deg, rgba(#163470,1) 0%, rgba(#2F5BB4,1) 100%);

/*
 * Shadows
 */
$box-shadow: 0px 3px 10px rgba(10,11,11,.1);
$box-shadow-bottom: 0px 6px 10px 0px rgba(56, 0, 0, 0.12);
$box-shadow-hover: 0px 3px 15px rgba(10,11,11,.3);
$box-shadow-overlay: 0px 16px 43px 0 rgba($color-dark, .38);
$box-shadow-contact: 0px 10px 30px 0 rgba($color-dark, .1);
$box-shadow-blue: 0 10px 30px 0 rgba($color-icon, 0.23);

/*
 * Transitions
 */
$transition-basic: all .25s ease-out;
$transition-opacity: opacity .25s ease-in-out 2s;
$transition-btn: all .4s ease-in-out;
$transition-slider: margin .6s ease-in-out;
$transition-slow: all 1s ease-in-out;

/*
 * Sliders
 */
$functions-slides-count: 32;