.social-block {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;

    .title {
        width: 100%;
    }

    .icon-link {
        align-items: center;
        border-radius: 50%;
        color: $color-white;
        cursor: pointer;
        display: flex;
        font-size: 1.7rem;
        height: 42px;
        justify-content: center;
        margin-right: 10px;
        text-align: center;
        transition: $transition-basic;
        width: 42px;

        &.facebook {
            background: $color-social-fb;

            .icon {
                left: -1px;
                position: relative;
                top: 1px;
            }

            &:active,
            &:focus,
            &:hover {
                background: darken($color-social-fb, 10%);
            }
        }

        &.twitter {
            background: $color-social-twitter;

            &:active,
            &:focus,
            &:hover {
                background: darken($color-social-twitter, 10%);
            }
        }

        &.linkedin {
            background: $color-social-linkedin;

            &:active,
            &:focus,
            &:hover {
                background: darken($color-social-linkedin, 10%);
            }
        }

        &.youtube {
            background: $color-social-youtube;
            color: $color-white;

            &:active,
            &:focus,
            &:hover {
                background: darken($color-social-youtube, 10%);
            }
        }

        .icon {
            display: inline-block;
            fill: $color-white;
            height: 19px;
            width: 19px;
        }

        &:active,
        &:focus,
        &:hover {
            color: rgba(255, 255, 255, .75);
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &.vertical {
        flex-direction: column;

        a {
            margin: 6px 0;
        }

        .icon {
            background: $color-white;
            border: 2px solid $color-gray;
            color: $color-gray;

            &:active,
            &:focus,
            &:hover {
                &.fa-facebook {
                    background: $color-social-fb;
                    border: 2px solid $color-social-fb;
                    color: $color-white;
                }

                &.fa-twitter {
                    background: $color-social-twitter;
                    border: 2px solid $color-social-twitter;
                    color: $color-white;
                }

                &.fa-linkedin {
                    background: $color-social-linkedin;
                    border: 2px solid $color-social-linkedin;
                    color: $color-white;
                }

                &.fa-youtube {
                    background: $color-social-youtube;
                    border: 2px solid $color-social-youtube;
                    color: $color-white;
                }
            }
        }
    }
}
